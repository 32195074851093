import { List, ListItem, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchImages } from '../../Redux/imageSlice';
import { BASE_URL } from '../../Redux/apiConfig';
import { Link } from 'react-router-dom';

const ImageList = () => {

    const dispatch = useDispatch();
    const images = useSelector((state) => state.images.images);
    //const files1 = useSelector((state) => state.files.files1);
    //const [images, setImages] = useState('');
   

    useEffect(() => {
        dispatch(fetchImages());
      // fetchImagesName();
    }, []);
  return (
    <div>
     <List>
            {images.map((image) => (
                <ListItem key={image.imageId}>
                    <ListItemText primary={image.title} secondary={`${image.description}`} />
           <Link  target="_blank" to={`${BASE_URL}/api/v1/images/image/${image.imageName}`} >View {image.imageId}</Link>
           <img key={image.imageId}  src={`${BASE_URL}/api/v1/images/image/${image.imageName}`}  style={{ width: '200px', height: 'auto', margin: '10px' }}/>
                </ListItem>


            ))}
        </List>
    </div>
  );
}

export default ImageList;
