import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos } from '../../Redux/videoSlice';
import { BASE_URL } from '../../Redux/apiConfig';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import VideoForm from './VideoForm';

const VideoList = () => {
    const dispatch = useDispatch();
    const videos = useSelector((state) => state.videos.videos);
   
    const [images, setImages] = useState('');
   

    useEffect(() => {
        dispatch(fetchVideos());
    //    fetchFilesName();
    }, []);

  return (
    <div>
      <VideoForm/>
 <List>
            {videos.map((video1) => (
                <ListItem key={video1.videoId}>
                    <ListItemText primary={video1.title} secondary={`${video1.description}`} />
           <Link  target="_blank" to={`${BASE_URL}/api/v1/videos/stream/${video1.videoId}`} >View {video1.videoId}</Link>
           {/* <img key={video1.id}  src={`${BASE_URL}/api/v1/${video1.filePath}`}  style={{ width: '200px', height: 'auto', margin: '10px' }}/> */}
               
            <video src={`${BASE_URL}/api/v1/videos/stream/${video1.videoId}`} controls width={400} height={300}></video> 
             </ListItem>
             

            ))}
        </List>

    </div>
  )
}

export default VideoList