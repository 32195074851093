import React from 'react';
import AdminDashboard from './AdminDashboard';


const Admin = () => {
  return (
    <div>
      
 <AdminDashboard/>
    </div>
  );
}

export default Admin;
