import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './apiConfig';


// Thunks for file upload and download
export const uploadFile = createAsyncThunk('files/uploadFile', async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${BASE_URL}/api/files/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
});

export const uploadMultipleFiles = createAsyncThunk('files/uploadMultipleFiles', async (files) => {
    const formData = new FormData();
   
    files.forEach((file) => formData.append('files', file));

    const response = await axios.post(`${BASE_URL}/api/files/uploadMultiple`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
});

export const fetchFiles = createAsyncThunk('files/fetchFiles', async () => {
    const response = await axios.get(`${BASE_URL}/api/files`);
    return response.data;
});

export const fetchFilesName = createAsyncThunk('files/fetchFilesName', async () => {
    const response = await axios.get(`${BASE_URL}/api/files/image/car.png`);
    console.log(response);
    
    return response.data;
});

const fileSlice = createSlice({
    name: 'files',
    initialState: {
        files: [],
        status: 'idle',
        error: null,
        files1: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.files.push(action.payload);
            })
            
            .addCase(uploadMultipleFiles.fulfilled, (state, action) => {
                state.files = [...state.files, ...action.payload];
            })
            .addCase(fetchFiles.fulfilled, (state, action) => {
                state.files = action.payload;
            })
            .addCase(fetchFilesName.fulfilled, (state, action) => {
                state.files1 = action.payload;
                console.log("hi");
                
                console.log(action.payload);
                
            })
            ;
    }
});

export default fileSlice.reducer;