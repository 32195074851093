import React from 'react'

import { Route, Routes } from 'react-router-dom'
import Sexecutive from '../Se/components/Sexecutive'
import SeDashboard from '../Se/components/SeDashboard'
import SeProjects from '../Se/components/SeProjects'
import SeRemarks from '../Se/components/SeRemarks'
import File from '../Se/components/File'
import Svisit from '../Se/components/Svisit'

const SeRouter = () => {
  return (
    <div>
<Routes>
            <Route path='/*' element={<Sexecutive />}/>
            <Route path='/sedashboard' element={<SeDashboard />}/>
            <Route path='/projects' element={<SeProjects />}/>
            <Route path='/svisit/:workId' element={<Svisit />}/>
            <Route path='/fileup' element={<File />}/>
           
        </Routes>

    </div>
  )
}

export default SeRouter