import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminapps, deleteAdminapp, changeAdminappStatus } from "../../Redux/aaReducer";
import {
  Button,
  List,
  ListAdminapp,
  ListAdminappText,
  IconButton,
  Switch,
  Box,
  Collapse,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AdminaModal from "./AdminaModal";
import { DataGrid } from "@mui/x-data-grid";

import { toast } from "react-toastify";

// import { format } from 'date-fns';

const AdminaList = () => {
  const dispatch = useDispatch();
  const adminapps = useSelector((state) => state.adminapps.adminapps);
  const adminappsId = useSelector((state) => state.adminapps.adminapps.id);
  const [selectedAdminapp, setSelectedAdminapp] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleChangeStatus = (id, active) => {
    dispatch(changeAdminappStatus({ id, active }))
      .then(() => {
        toast.success("Orgutp status updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update orgutp status!");
      });
  };

  // const renderSwitch = (row) => (
  //   <Switch
  //     checked={row.active}
  //     onChange={(e) => handleChangeStatus(row.id, e.target.checked)}
  //   />
  // );
  useEffect(() => {
    console.log(adminapps);

    dispatch(fetchAdminapps());
  }, [dispatch]);

  const handleEdit = (adminapp) => {
    setSelectedAdminapp(adminapp);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteAdminapp(id));
  };

  const handleAdd = () => {
    setSelectedAdminapp(null);
    setModalOpen(true);
  };

  const columns = [
    { field: "id", headerName: "ID", },
    { field: "chead", headerName: "Chargeable Head", },
    { field: "pwid", headerName: "Project/work Id", Collapse },
    { field: "pwname", headerName: "Name of Project/work", },
    { field: "description", headerName: "Description",  },
    {
      field: `doaa`,
      headerName: "Date Of Admin Approval",
      width: 250,
      valueFormatter: (value) => value && value.split("-").reverse().join("-"),
      //valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy'),
    },

    {
      field: "Action1",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      //renderCell: renderSwitch,
      renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.id, e.target.checked)} />,
      //   onChange={(e) => handleChangeStatus(row.id, e.target.checked)}
    },
    {
      field: "Action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",

      width: 60,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      //   field: 'Action',
      // headerName: 'Action',
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleDelete(row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <Button variant="contained" onClick={handleAdd}>
        Add Administrative Approval [{adminapps.length}]
      </Button>
      <Box height={20} />
      {/* <List>
                {adminapps.map((adminapp) => (
                    <ListAdminapp key={adminapp.id}>
                        <ListAdminappText primary={adminapp.name} secondary={adminapp.description} error={adminapp.dob}  />
                        <ListAdminappText  primary={adminapp.dob.split('-').reverse().join('-')}  />
                        <Switch checked={adminapp.active} disabled />
                        <IconButton onClick={() => handleEdit(adminapp)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(adminapp.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListAdminapp>
                ))}
            </List> */}
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={adminapps}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          //checkboxSelection
        />
      </div>

      <AdminaModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        currentAdminapp={selectedAdminapp}
      />
    </div>
  );
};

export default AdminaList;
