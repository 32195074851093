import React from 'react'
import HomePage from '../Pages/HomePage'
import { Route } from 'react-router-dom'

const PublicRoutes = () => {
  return [

<Route path="/login" exact  element={<HomePage  />} />,
<Route path="/register" exact  element={<HomePage  />} />

  ]
}

export default PublicRoutes