import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSvisits, deleteSvisit } from "../../Redux/svisitSlice";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../Redux/apiConfig";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const SvisitTable = () => {
  const dispatch = useDispatch();
  const { svisits } = useSelector((state) => state.svisits);
  const { simages } = useSelector((state) => state.svisits.simages);

  useEffect(() => {
    dispatch(fetchSvisits());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this svisit?")) {
      dispatch(deleteSvisit(id));
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "review", headerName: "Review", width: 200 },
    { field: "remark", headerName: "Remarks", width: 100 },
    { field: "created", headerName: "Created Date", width: 100 },
    { field: "createdBy", headerName: "Created By", width: 100 },
    {
      field: "simages",
      headerName: "Images",
      width: 500,
      height:300,
      
      renderCell: (params) => (
        <div style={{display:'flex'}}>
          {params.row.simages?.map((image) => (
            <Stack spacing={2} direction={'row'}>
            <>
                <a
            color="green"
                target="_blank"
                href={`${BASE_URL}/api/svisits/image/${image.simageName}`}
              >
                View {image.id}
              </a>||
              <img
                src={`${BASE_URL}/api/svisits/image/${image.simageName}`}
                // alt={image.simageName}
                alt="Avatar" style={{ width: '50px', height: '50px', zIndex:20 }}
                key={image.id}
                
              />
              
            </></Stack>
          ))}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
    // {
    //     field: "Action",
    //     headerName: "Action",
    //     description: "This column has a value getter and is not sortable.",

    //     width: 60,
    //     renderCell: ({ row }) => (
    //       <IconButton onClick={() => handleEdit(row)}>
    //         <EditIcon />
    //       </IconButton>
    //     ),
    //   },
  ];

  return (
    <>
      {/* <Grid2 container spacing={3}>
        <Grid2 item xs={6}>123s</Grid2>
        <Grid2 item xs={6}>
        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: 56, justifyContent: 'center', position:"relative" }}>
        <Avatar alt="Svisit" src={rose} sx={{
          width: 80,
          height: 80,
          position: "absolute",
          left: -40,  // 50% overlap outside the card
          top: -5,
          zIndex:20
        }}/>
      </Box>
      <CardContent>
        <Typography variant="h5" component="div">
          Title
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Some description goes here.
        </Typography>
      </CardContent>
    </Card>
        </Grid2>
      </Grid2> */}

      <div style={{ height: 500,}}>
        <DataGrid
          rows={svisits}
          columns={columns}
          getRowId={(row) => row.id}
          pageSize={5}
          
        />
      </div>
    </>
  );
};

export default SvisitTable;
