import React from 'react';
import Home from './Home';
import Dashboard from './Dashboard';

const Ceo = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default Ceo;
