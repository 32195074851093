import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { uploadVideo } from "../../Redux/videoSlice";
import { useDispatch } from "react-redux";

const VideoForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [meta, setMeta] = useState({
    title: "",
    description: "",
  });
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  function formFieldChange(event) {
    // console.log(event.target.name);
    // console.log(event.target.value);
    setMeta({
      ...meta,
      [event.target.name]: event.target.value,
    });
  }

  function handleSave(formEvent) {
    formEvent.preventDefault();
    if (!selectedFile) {
      alert("Select File !!");
      return;
    }
   // console.log(selectedFile, meta);
dispatch(uploadVideo({selectedFile, meta}));
resetForm();

    //submit the file to server:
    // saveVideoToServer(selectedFile, meta);
  }

  function resetForm() {
    setMeta({
      title: "",
      description: "",
    });
   
    setSelectedFile(null);
    setUploading(false);
    // setMessage("");
  }

  function handleFileChange(event) {
   // console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  }
  return (
    <>
      <Card>
        <Typography variant="h5" align="center">
          Video Uploading...
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Title"
              value={meta.title}
              onChange={formFieldChange}
              margin="normal"
              name="title"
            />
            <TextField
              fullWidth
              label="Description"
              value={meta.description}
              onChange={formFieldChange}
              margin="normal"
              name="description"
            />
            <TextField
              fullWidth
              type="file"
              label="Select File to be Upload"
              onChange={handleFileChange}
              margin="normal"
              name="file"
            />
            <Box height={20} />
            <Button variant="contained" onClick={handleSave}>
              Upload...
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default VideoForm;
