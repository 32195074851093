import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addCwork, updateCwork } from '../../Redux/cworkReducer';
import { useParams } from 'react-router-dom';

const CworkForm = ({currentCwork}) => {
    const {workId}=useParams();
    console.log(workId);
    
    const [nowork, setNowork] = useState('');
    const [nol, setNol] = useState('');
    const [nod, setNod] = useState('');
    const [sdate, setSdate] = useState('');
    const [edate, setEdate] = useState('');
    const [description, setDescription] = useState('');
     const [selectedFile, setSelectedFile] = useState([]);
    const [active, setActive] = useState(true);
    const dispatch = useDispatch();
    // const handleFileChange = (e) => {
    //     setSelectedFile(e.target.files[0]);
    // };
    // const handleMultipleFilesChange = (e) => {
    //     setSelectedFiles(e.target.files);
    // };
    useEffect(() => {
        if (currentCwork) {
            setNowork(currentCwork.nowork);
            setNol(currentCwork.nol);
            setNod(currentCwork.nod);
            setSdate(currentCwork.sdate);
            setEdate(currentCwork.edate);
            setDescription(currentCwork.description);   
             setSelectedFile(currentCwork.selectedFile) 
            setActive(currentCwork.active);
        }
    }, [currentCwork]);

    const handleSave = () => {
        const cwork = { nowork, nol,nod,sdate,edate,description, active,workId:workId};
        console.log(cwork);
        
        if (currentCwork!=null) {
            dispatch(updateCwork({ cworkId: currentCwork.cworkId, cwork })).then(() => {
                toast.success('Contractor work updated successfully!');  
                resetForm();
            }).catch((error) => {
                toast.error('Failed to add Contractor work !');
            });
          
        } else {
            dispatch(addCwork(cwork)).then(() => {
            toast.success('Contractor work addded successfully!'); 
            resetForm();
        }).catch((error) => {
            toast.error('Failed to add Contractor work!',error);
        });
            
        }
        
    };
    const resetForm=()=>{
        nowork='';
        nol='' ;
        nod='';
        description='';
        sdate='';
        edate='';

    }
  return (
    <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 1024 }}>
          {workId}
    <Typography variant='h6'>Contractor Work... <hr/></Typography>
    <Grid container spacing={2}>
    <Grid item xs={12}>
    <TextField
                    fullWidth
                    label="Nature of Work"
                    multiline
                    rows={2}
                    value={nowork}
                    onChange={(e) => setNowork(e.target.value)}
                    margin="normal"
                />
        </Grid>
        <Grid item xs={4}>
        <TextField
                    fullWidth
                    label="No. Of Labour"
                    type='number'
                    value={nol}
                    onChange={(e) => setNol(e.target.value)}
                    margin="normal"
                />
        </Grid>

        <Grid item xs={4}><TextField
                    fullWidth
                    label="No. Of Days"
                    type='number'
                    value={nod}
                    onChange={(e) => setNod(e.target.value)}
                    margin="normal"
                /></Grid>
        <Grid item xs={4}> <TextField
                type='date'
                    fullWidth
                    label="Start Date"
                   placeholder='Start Date'
                   value={sdate}
                    onChange={(e) => setSdate(e.target.value)}
                    margin="normal"
                /></Grid>

<Grid item xs={4}> <TextField
                type='date'
                    fullWidth
                    label="End Date"
                   placeholder='End Date'
                    value={edate}
                    onChange={(e) => setEdate(e.target.value)}
                    margin="normal"
                /></Grid>
<Grid item xs={4}>
    {/* <TextField type="file" onChange={handleFileChange}/> */}
 {/* inputProps={{ multiple: true }} 
 onChange={handleMultipleFilesChange}  */}

</Grid><Grid item xs={4}> <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                /></Grid>
<Grid item xs={12}> <TextField
                    fullWidth
                    autoComplete='off'
                    label="Remarks"
                    value={description}
                    multiline
          rows={2}
                     onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                /> </Grid>
<Box height={20}/>
                <Button variant="contained" 
                 onClick={handleSave}              
                >Save</Button>
        </Grid>

    </Box>
  )
}

export default CworkForm