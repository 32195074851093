import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Box, TextField, Switch, FormControlLabel, Typography } from '@mui/material';
import { addAdminapp, updateAdminapp } from '../../Redux/aaReducer'

const AdminaModal = ({ open, onClose, currentAdminapp }) => {
    const [chead, setChead] = useState('');
    const [pwid, setPwid] = useState('');
    const [pwname, setPwname] = useState('');
    const [description, setDescription] = useState('');
    const [doaa, setDoaa] = useState('');
    const [active, setActive] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentAdminapp) {
            setChead(currentAdminapp.chead);
            setPwid(currentAdminapp.pwid);
            setPwname(currentAdminapp.pwname);
            setDescription(currentAdminapp.description);
            setDoaa(currentAdminapp.doaa)
            setActive(currentAdminapp.active);
        }
    }, [currentAdminapp]);

    const handleSave = () => {
        const adminapp = {chead, pwid, pwname, description,doaa, active };
        if (currentAdminapp) {
            dispatch(updateAdminapp({ id: currentAdminapp.id, adminapp }))
          
        } else {
            dispatch(addAdminapp(adminapp)).then(()=>
            {
                setChead('');
                setPwid("");
                setPwname("");
                setDescription("");
                setDoaa("");
            })
            
        }
        onClose();
    };

    

    return (
        <Modal open={open} onClose={onClose} >
           
            <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 500 }}>
                
            <Typography variant='h6'>Admin. Approval <hr/></Typography>
            <TextField
                    fullWidth
                    label="Chargeable Head"
                    value={chead}
                    onChange={(e) => setChead(e.target.value)}
                    margin="normal"
                />
            <TextField
                    fullWidth
                    label="Project/work Id"
                    value={pwid}
                    onChange={(e) => setPwid(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Name of Project/work"
                    value={pwname}
                    onChange={(e) => setPwname(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                />
                <TextField
                type='date'
                    fullWidth
                   // label="Date of Birth"
                   placeholder='Date of Birth'
                    value={doaa}
                    onChange={(e) => setDoaa(e.target.value)}
                    margin="normal"
                />
                <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                />
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </Box>
        </Modal>
    );
};




export default AdminaModal;
