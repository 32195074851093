import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Box, TextField, Switch, FormControlLabel, Typography } from '@mui/material';
import { addOrgtype, updateOrgtype } from '../../Redux/otReducer';
import { toast } from 'react-toastify';
const OrgtypeModal = ({ open, onClose, currentOrgtype }) => {
    const [orgtypeName, setOrgtypeName] = useState('');
    const [description, setDescription] = useState('');
   
    const [active, setActive] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentOrgtype) {
            setOrgtypeName(currentOrgtype.orgtypeName);
            setDescription(currentOrgtype.description);    
            setActive(currentOrgtype.active);
        }
    }, [currentOrgtype]);

    const handleSave = () => {
        const orgtype = { orgtypeName, description, active };
        if (currentOrgtype) {
            dispatch(updateOrgtype({ orgtypeId: currentOrgtype.orgtypeId, orgtype })).then(() => {
                toast.success('Orgnization  type updated successfully!');   
            }).catch((error) => {
                toast.error('Failed to add Orgnization type!');
            });
          
        } else {
            dispatch(addOrgtype(orgtype)).then(() => {
            toast.success('Orgnization type addded successfully!');   
        }).catch((error) => {
            toast.error('Failed to add Orgnization type!');
        });
            
        }
        onClose();
    };


    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 500 }}>
            <Typography variant='h6' color={'darkblue'}>Manage Organization Type</Typography><hr/>
                <TextField
                    fullWidth
                    label="Organization Unit Name"
                    value={orgtypeName}
                    onChange={(e) => setOrgtypeName(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                />
                {/* <TextField
                type='date'
                    fullWidth
                   // label="Date of Birth"
                   placeholder='Date of Birth'
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    margin="normal"
                /> */}
                <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                />
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </Box>
        </Modal>
    );
};

export default OrgtypeModal;
