import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCworks } from '../../Redux/cworkReducer';

const CworkList = () => {
  
  const cworks = useSelector((state) => state.cworks.cworks);
  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(fetchCworks())
  },[dispatch])

  const columns = [
    { field: "cworkid", headerName: "ID",  },
    { field: "nowork", headerName: "nature of Work",
      // renderCell: (params) =>  <Link to={`/contractor/cwork/${params.id}`}>{params.value}</Link>
        // <Link to="/contractor/cwork/">{params.row.workname}</Link>,
    },
     { field: "sdate", headerName: "Start Date"},
     { field: "edate", headerName: "End Date"},
     { field: "nod", headerName: "No. of Days"},
     { field: "nol", headerName: "No of Labour"},
    // { field: "sdDateComplition", headerName: "Date of Complition"},
    // { field: "srDateComplition", headerName: "Rivised Date of Complition"},
    // { field: "budgetDYear", headerName: "Budget During Year"},
    // { field: "phyStatus", headerName: "Physical Work Status(%) "},
    // { field: "finStatus", headerName: "Finacial Work Status(%)"},   

     { field: "description", headerName: "Description" },   
  
  ];
  return (
    <div>

    <Box height={70}/>
    <Box>
    <div style={{ height: 400, width: '100%'  }}>
       <DataGrid
         rows={cworks}
         columns={columns}
         getRowId={(row) => row.cworkid}
         initialState={{
           pagination: {
             paginationModel: { page: 0, pageSize: 5 },
           },
         }}
         pageSizeOptions={[5, 10, 50, 100]}
         slots={{ toolbar: GridToolbar }}
         //checkboxSelection
       />
     </div>
         </Box>
       </div>
  )
}

export default CworkList