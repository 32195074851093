import { configureStore } from '@reduxjs/toolkit';
import itemsReducer from './aaReducer';
import orgtypesReducer from './otReducer';
import orgusReducer from './ouReducer';
import postsReducer from './postReducer';
import desigsReducer from './desigReducer';
 import employeeReducer from './employeeSlice';
import adminappsReducer from './aaReducer';
import fileReducer from './fileSlice';
import authReducer from './authSlice';
import venderReducer from './venderReducer';
import workReducer from './workReducer';
import divisionReducer from './divisionReducer';
import empsReducer from './empReducer';
import cworkReducer from './cworkReducer';
import videoReducer from './videoSlice';
import userReducer from './userReducer'
import imageReducer from './imageSlice'
import jvisitReducer from './jvisitSlice'
import svisitReducer from './svisitSlice'
import cvisitReducer from './cvisitSlice'
import xvisitReducer from './xvisitSlice'
const store = configureStore({
  reducer: {
    auth: authReducer,
    adminapps: adminappsReducer,
    orgtypes:orgtypesReducer,
    orgus:orgusReducer,
    posts:postsReducer,
    desigs:desigsReducer,
    divisions:divisionReducer,
     emps:empsReducer,
    employees:employeeReducer,
    files: fileReducer,
    videos: videoReducer,
    images: imageReducer,
    venders:venderReducer,
    works:workReducer,
    cworks:cworkReducer,
    users:userReducer,
    jvisits:jvisitReducer,
    svisits:svisitReducer,
    cvisits:cvisitReducer,
    xvisits:xvisitReducer,

  },
});

export default store;