import React from 'react'
import Sidenav from './Sidenav'
import Navbar from '../../Pages/components/Navbar'
import { Box } from '@mui/material'
import DivisionList from './DivisionList'

const Division = () => {
  return (
<div>

<Navbar/> <Box height={30}/>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <h1>Divisions</h1><hr/>
          <DivisionList />
        </Box>
      </Box>
    </div>
  )
}

export default Division