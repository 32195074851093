import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Je from '../JE/components/Je';
import JeDashboard from '../JE/components/JeDashboard';
import JeProjects from '../JE/components/JeProjects';

import JeRemarks from '../JE/components/JeRemarks'
import File from '../JE/components/File'
import Jvisit from '../JE/components/Jvisit';
import Video from '../JE/components/Video';
const JeRouter = () => {
  return (
    <div>
      <Routes>
            <Route path='/*' element={<Je />}/>
            <Route path='/dashboard' element={<JeDashboard />}/>
            {/* <Route path='/jremark/:workId' element={<JeRemarks />}/> */}
            <Route path='/projects' element={<JeProjects />}/>
            <Route path='/video' element={<Video/>}/>
            <Route path='/fileup' element={<File />}/>
            <Route path='/jvisit/:workId' element={<Jvisit />}/>


        </Routes>
    </div>
  );
}

export default JeRouter;
