import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createXvisit, updateXvisit } from '../../Redux/xvisitSlice';
import { useParams } from 'react-router-dom';

const XvisitForm = ({xvisit,isEdit}) => {
    const {workId}=useParams();

    const [title, setTitle] = useState(xvisit ? xvisit.title : '');
    const [remark, setRemark] = useState(xvisit ? xvisit.remark : '');
    const [images, setImages] = useState([]);

    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        setImages(e.target.files);
    };

    const handleSubmit = () => {
        const formData = new FormData();
        // formData.append('name', name);
        // formData.append('age', age);
        formData.append('xvisit', new Blob([JSON.stringify({ title, remark,workId:workId })], { type: 'application/json' }));
        Array.from(images).forEach(image => {
            formData.append('images', image);
        });

        if (isEdit) {
            dispatch(updateXvisit({ id: xvisit.id, xvisitData: formData }));
        } else {
            dispatch(createXvisit(formData));
        }
    };
  return (
   <>
    <div>
    <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 1024 }}>
          
          <Typography variant='h6'>XEN Visiting Info... <hr/></Typography>
          <Grid container spacing={2}>
          <Grid item xs={12}>

          <form>
            <TextField label="Review" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth /><Box height={10}/>
            <TextField label="Remarks" value={remark} onChange={(e) => setRemark(e.target.value)} fullWidth /><Box height={10}/>
            <input type="file" multiple onChange={handleFileChange} />
            <Button variant='contained' onClick={handleSubmit}>{isEdit ? 'Update' : 'Create'}</Button>
        </form>

            </Grid>
            </Grid>
            </Box>
    </div>
   </>
  );
}

export default XvisitForm;
