import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Ceo/components/Dashboard';
import AdminApp from '../Ceo/components/AdminApp';
import Ceo from '../Ceo/components/Ceo';
import AdminDashboard from '../Admin/components/AdminDashboard';
import Admin from '../Admin/components/Admin';
import OrgType from '../Admin/components/OrgType';
import Orgunit from '../Admin/components/Orgunit';
import Designation from '../Admin/components/Designation';
import Post from '../Admin/components/Post';
import Employee from '../Admin/components/Employee';
import User from '../Admin/components/User';
import Division from '../Admin/components/Division';
import Profile from '../Admin/components/Profile';


const AdminRouter = () => {
  return (
    <div>
      <Routes>
            <Route path='/*' element={<Admin />}/>
            <Route path='/admindashboard' element={<AdminDashboard />}/>
            <Route path='/orgtype' element={<OrgType />}/>
            <Route path='/division' element={<Division />}/>
            <Route path='/orgunit' element={<Orgunit />}/>
            <Route path='/desig' element={<Designation />}/>
            <Route path='/post' element={<Post />}/>
            <Route path='/emp' element={<Employee />}/>
            <Route path='/user' element={<User />}/>
            <Route path='/profile' element={<Profile />}/>
        </Routes>
    </div>
  );
}

export default AdminRouter;
