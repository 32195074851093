import { Card, CardContent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersDetailsById } from "../../Redux/userReducer";

const AdminProfile = () => {
  const dispatch = useDispatch();
  const getusersdetailsbyid = useSelector((state) => state.users.usersById);
  useEffect(() => {
    console.log(getusersdetailsbyid);

    dispatch(fetchUsersDetailsById());
  }, [dispatch]);

  return (
    <div>
      <>
        <Card sx={{maxWidth:500}}>
          <CardContent>
            <Typography variant="h4" color="red">
              {" "}
              Email : {getusersdetailsbyid.email}
            </Typography>
            <Typography variant="h4" color="red">
              {" "}
              Name : {getusersdetailsbyid.name}
            </Typography>
            <Typography variant="h4" color="red">
              {" "}
              Mobile : {getusersdetailsbyid.mobile}
            </Typography>
            <Typography variant="h4" color="red">
              {" "}
              Designation : {getusersdetailsbyid.designame}
            </Typography>
            <Typography variant="h4" color="red">
              {" "}
              Division : {getusersdetailsbyid.orgname}
            </Typography>
            <Typography variant="h4" color="red">
              {" "}
              Post : {getusersdetailsbyid.postname}
            </Typography>
          </CardContent>
        </Card>
      </>
    </div>
  );
};

export default AdminProfile;
