import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import { axios, BASE_URL } from './apiConfig';



export const fetchOrgus = createAsyncThunk('orgus/fetchOrgus', async (thunkAPI) => {

  try {
    const response = await axios.get(`${BASE_URL}/api/orgus`);
    const data = response.data;
    console.log(data);
    
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}


});
export const fetchOrgusIdName = createAsyncThunk('orgus/fetchOrgusIdName', async (thunkAPI) => {

  try {
    const response = await axios.get(`${BASE_URL}/api/orgus/getorguidname`);
    const data = response.data;
    console.log(data);
    
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}


});

export const fetchActiveOrgus = createAsyncThunk('orgus/fetchActiveOrgus', async () => {
  const response = await axios.get(`${BASE_URL}/api/orgus/count-orgus`); 
  return response.data;
});
export const fetchInActiveOrgus = createAsyncThunk('orgus/fetchInActiveOrgus', async () => {
  const response = await axios.get(`${BASE_URL}/api/orgus/count-inaorgus`);
  return response.data;
});

export const addOrgu = createAsyncThunk('orgus/addOrgu', async (orgu,thunkAPI) => {
  // const response = await axios.post(`${BASE_URL}/api/orgus`, orgu);
  // return response.data;

  try {
    const response = await axios.post(`/api/orgus/`,orgu);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}

});

export const updateOrgu = createAsyncThunk('orgus/updateOrgu', async ({ id, orgu }) => {
  const response = await axios.put(`${BASE_URL}/api/orgus/${id}`, orgu);
  return response.data;
});

export const deleteOrgu = createAsyncThunk('orgus/deleteOrgu', async (id) => {
  await axios.delete(`${BASE_URL}/api/orgus/${id}`);
  return id;
}); 

export const changeOrguStatus = createAsyncThunk('orgus/changeOrguStatus', async ({ id, active }) => {
  const response = await axios.patch(`${BASE_URL}/api/orgus/${id}/status?active=${active}`);
  return response.data;
});
const orgusSlice = createSlice({
  name: 'orgus',
  initialState: {
    orgus: [],
    status: 'idle',
    error: null,
    orgus1: [],
    orgus2: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrgus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrgus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orgus = action.payload;        
   
      })
      .addCase(fetchOrgusIdName.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orgusIdName = action.payload;        
   
      })
      .addCase(fetchActiveOrgus.fulfilled, (state, action) => { 
        state.status = 'succeeded';
        state.orgus1 = action.payload;
      })
      .addCase(fetchInActiveOrgus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orgus2 = action.payload;
      })

      .addCase(fetchOrgus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      
      .addCase(addOrgu.fulfilled, (state, action) => {
        state.orgus.push(action.payload);
      })
      .addCase(updateOrgu.fulfilled, (state, action) => {
        const index = state.orgus.findIndex(orgu => orgu.id === action.payload.id);
        state.orgus[index] = action.payload;
      })
      .addCase(deleteOrgu.fulfilled, (state, action) => {
        state.orgus = state.orgus.filter(orgu => orgu.id !== action.payload);
      })

      .addCase(changeOrguStatus.fulfilled, (state, action) => {
        const index = state.orgus.findIndex((orgu) => orgu.id === action.payload.id);
        state.orgus[index] = action.payload;
    })
  },
});

export default orgusSlice.reducer;