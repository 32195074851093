import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchXvisits, deleteXvisit, fetchImages } from "../../Redux/xvisitSlice";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../Redux/apiConfig";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

const XvisitTable = () => {
  const dispatch = useDispatch();
  const { xvisits } = useSelector((state) => state.xvisits);
  const { ximages } = useSelector((state) => state.xvisits.ximages);

  useEffect(() => {
    dispatch(fetchXvisits());
    dispatch(fetchImages());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteXvisit(id));
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "title", headerName: "Reviews", width: 200 },
    { field: "remark", headerName: "Remarks", width: 100 },
    { field: "created", headerName: "Created Date", width: 100 },
    { field: "createdBy", headerName: "Created By", width: 100 },
    {
      field: "ximages",
      headerName: "Images",
      width: 300,
      renderCell: (params) => (
        <div style={{display:'flex'}}>
          {params.row.ximages.map((image1) => (
            <>
              <Stack spacing={2} direction={"row"}>
                <img
                  src={`${BASE_URL}/api/xvisits/image/${image1.ximageName}`}
                  alt={image1.ximageName}
                  key={image1.id}
                  width={25}
                />{" "}
                <a
                  target="_blank"
                  href={`${BASE_URL}/api/xvisits/image/${image1.ximageName}`}
                >
                  View {image1.id}
                </a>{" "}
              </Stack>
            </>
          ))}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
    // {
    //     field: "Action",
    //     headerName: "Action",
    //     description: "This column has a value getter and is not sortable.",

    //     width: 60,
    //     renderCell: ({ row }) => (
    //       <IconButton onClick={() => handleEdit(row)}>
    //         <EditIcon />
    //       </IconButton>
    //     ),
    //   },
  ];

  return (
    <>
      {/* <Grid2 container spacing={3}>
        <Grid2 item xs={6}>123s</Grid2>
        <Grid2 item xs={6}>
        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: 56, justifyContent: 'center', position:"relative" }}>
        <Avatar alt="User" src={rose} sx={{
          width: 80,
          height: 80,
          position: "absolute",
          left: -40,  // 50% overlap outside the card
          top: -5,
          zIndex:20
        }}/>
      </Box>
      <CardContent>
        <Typography variant="h5" component="div">
          Title
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Some description goes here.
        </Typography>
      </CardContent>
    </Card>
        </Grid2>
      </Grid2> */}

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid rows={xvisits} columns={columns}       getRowId={(row) => row.id} pageSize={10} />
      </div>
    </>
  );
};

export default XvisitTable;

