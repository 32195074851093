import React from 'react';

import Navbar from '../../Pages/components/Navbar';
import { Box, Typography } from '@mui/material';
import SvisitForm from './SvisitForm';
import SvisitTable from './SvisitTable';
import Sidenav from './Sidenav';

const Svisit = () => {
  return (
    <>
    <Navbar/> <Box height={70}/>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant='h3' align='center'  > SE Sites Visites...</Typography><hr/>
        <Box height={15}/>
         
          <SvisitForm />
          <SvisitTable/>
        </Box>
      </Box>
   </>
  );
}

export default Svisit;
