import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import { axios, BASE_URL } from './apiConfig';


export const fetchPosts = createAsyncThunk('posts/fetchPosts', async (thunkAPI) => {
  try {
    const response = await axios.get(`/api/posts`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchPostsIdName = createAsyncThunk('posts/fetchPostsIdName', async (thunkAPI) => {
  try {
    const response = await axios.get(`/api/posts/getpostidname`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const addPost = createAsyncThunk('posts/addPost', async (post,thunkAPI) => {
  // const response = await axios.post(`${BASE_URL}/api/posts`, post);
  // return response.data;
  try {
    const response = await axios.post(`/api/posts`,post);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}

});

export const updatePost = createAsyncThunk('posts/updatePost', async ({ id, post }) => {
  const response = await axios.put(`${BASE_URL}/api/posts/${id}`, post);
  return response.data;
});

export const deletePost = createAsyncThunk('posts/deletePost', async (id) => {
  await axios.delete(`${BASE_URL}/api/posts/${id}`);
  return id;
}); 

export const changePostStatus = createAsyncThunk('posts/changePostStatus', async ({ id, active }) => {
  const response = await axios.patch(`${BASE_URL}/api/posts/${id}/status?active=${active}`);
  return response.data;
});

export const fetchActivePosts = createAsyncThunk('posts/fetchActivePosts', async () => {
  const response = await axios.get(`${BASE_URL}/api/posts/posts-activecounts`); 
  return response.data;
});
export const fetchInActivePosts = createAsyncThunk('posts/fetchInActivePosts', async () => {
  const response = await axios.get(`${BASE_URL}/api/posts/posts-inactivecounts`);
  return response.data;
});

const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    posts: [],
    status: 'idle',
    error: null,
    posts1: [],
    posts2: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts = action.payload;
      })
      .addCase(fetchActivePosts.fulfilled, (state, action) => { 
        state.status = 'succeeded';
        state.posts1 = action.payload;
      })
      .addCase(fetchInActivePosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts2 = action.payload;
      })
      .addCase(fetchPostsIdName.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.postsIdName = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addPost.fulfilled, (state, action) => {
        state.posts.push(action.payload);
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        const index = state.posts.findIndex(post => post.id === action.payload.id);
        state.posts[index] = action.payload;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.posts = state.posts.filter(post => post.id !== action.payload);
      })

      .addCase(changePostStatus.fulfilled, (state, action) => {
        const index = state.posts.findIndex((post) => post.id === action.payload.id);
        state.posts[index] = action.payload;
    })
  },
});

export default postsSlice.reducer;