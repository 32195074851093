import React from 'react'
import ContractorProjects from '../Contractor/components/ContractorProjects'
import ContractorDashboard from '../Contractor/components/ContractorDashboard'
import Contractor from '../Contractor/components/Contractor'
import { Route, Routes } from 'react-router-dom'
import ContractorWork from '../Contractor/components/ContractorWork'
import Video from '../Contractor/components/Video'
import File from '../Contractor/components/File'
import Image from '../Contractor/components/Image'

const ContractorRouter = () => {
  return (
    <div>
<Routes>
            <Route path='/*' element={<Contractor />}/>
            <Route path='/contractordashboard' element={<ContractorDashboard />}/>
            <Route path='/projects' element={<ContractorProjects />}/>
            <Route path='/cwork/:workId' element={<ContractorWork />}/>
            <Route path='/video' element={<Video/>}/>
            {/* <Route path='/fileup' element={<File />}/> */}
            <Route path='/imageup' element={<Image />}/>
           
        </Routes>

    </div>
  )
}

export default ContractorRouter