import React from 'react'
import Navbar from '../../Pages/components/Navbar';
import Sidenav from './Sidenav';
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import CountUp from 'react-countup';

const SeDashboard = () => {
  return (
    <div>
    <Navbar /> <Box height={70} />
    <Box sx={{ display: "flex" }}>
      <Sidenav />
 
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant='h4' align='center' py={2}>SE Dashbaord</Typography><hr/>
      <Box height={20}/>
      <Grid container spacing={2}>
          <Grid item xs={8}>
              <Stack spacing={2} direction={"row"}>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white"}} >
            {/* sx={{ maxWidth: 345, height:300 }} */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" color={'lightblue'}>
                  ALL Projects: <CountUp delay={0.3} end= "50" duration={2.9} />
                  {/* <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> */}
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'lightgreen'}>
                  Active Project: <CountUp delay={0.3} end= "45" duration={2.9} />
                   {/* <CountUp delay={0.3} end= {activeCount} duration={2.9} /> */}
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'#fe5644'}>
                  InActive Project: <CountUp delay={0.3} end= "5" duration={2.9} />
                  {/* <CountUp delay={0.3} end= {inactiveCount} duration={2.9} /> */}
                </Typography>
               
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Underprogress Projects:<CountUp delay={0.3} end= "42" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {/* projects <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> */}
                  {/* projects <CountUp delay={0.3} end= "8" duration={2.9} /> */}
                </Typography>
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Completed Projects:<CountUp delay={0.3} end= "6" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {/* projects <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> */}
                  {/* projects <CountUp delay={0.3} end= "8" duration={2.9} /> */}
                </Typography>
              </CardContent>
            
            </Card>
            </Stack>
          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                Delay Projects: 
               </Typography>
               <Typography variant="body2" color="#ffffff">
                <CountUp delay={0.3} end= "6" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
            Pending Projects:
               </Typography>
               <Typography variant="body2" color="#ffffff" >
               <CountUp delay={0.3} end= "2" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
           </Stack>
          </Grid>
          <Box height={20} />
        </Grid>
      </Box>
    </Box>
  </div>
  );
}

export default SeDashboard