import React from 'react'
import Sidenav from './Sidenav'
import Navbar from '../../Pages/components/Navbar';
import { Box, colors, Typography } from '@mui/material'
import Fileupload from './Fileupload'
import FileList from './FileList'

const File = () => {
  return (
<div>

<Navbar/> <Box height={70}/>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant='h3' align='center'  > File Upload Page</Typography><hr/>
        <Box height={15}/>
         
          <Fileupload />
          <FileList/>
        </Box>
      </Box>
    </div>
  )
}

export default File