import React from 'react';
import PostList from './PostList';
import Navbar from '../../Pages/components/Navbar';
import Sidenav from './Sidenav';
import { Box, Typography } from '@mui/material';
const Post = () => {
  return (
    <div>

    <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant='h5' color={'darkblue'} align='center'>Post Management</Typography>
            <hr/><Box height={10}/>
              <PostList />
            </Box>
          </Box>
        </div>
  );
}

export default Post;
