import React, { uheEffect, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrgus,
  deleteOrgu,
  changeOrguStatus,
} from "../../Redux/ouReducer";
import {
  Button,
  List,
  ListOrguText,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
  Typography,
  Container,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OrguModal from "./OrguModal";
import { toast } from "react-toastify";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts";
const OrgunitList = () => {
  const dispatch = useDispatch();
  const orgus = useSelector((state) => state.orgus.orgus);
  const [selectedOrgu, setSelectedOrgu] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentOrguId, setCurrentOrguId] = useState(null);

  useEffect(() => {
    console.log(orgus);

    dispatch(fetchOrgus());
  }, [dispatch]);

  const handleEdit = (orgu) => {
    setSelectedOrgu(orgu);
    setModalOpen(true);
  };

  const handleDeleteOrgu = (id) => {
    dispatch(deleteOrgu(id))
      .then(() => {
        toast.success("Iten deleted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to add orgu!");
      });
  };
  const handleAdd = () => {
    setModalOpen(true);
    setSelectedOrgu(null);
  };
  const handleChangeStatus = (id, active) => {
    dispatch(changeOrguStatus({ id, active }))
      .then(() => {
        toast.success("Orgu status updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update orgu status!");
      });
  };

  const handleDialogOpen = (type, orguId) => {
    setDialogType(type);
    setCurrentOrguId(orguId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentOrguId(null);
    setDialogType("");
  };

  const handleConfirmAction = () => {
    if (dialogType === "delete") {
      handleDeleteOrgu(currentOrguId);
    }
    handleDialogClose();
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "orguname", headerName: "Orgnization Name", width: 290 },
    { field: "description", headerName: "Description", width: 300 },
    { field: "orgtypeName", headerName: "Orgnization Type", width: 300 },
    { field: "fullName", headerName: "Created By", width: 300 },
    // { field: 'orgtype', headerName: 'Organization Type', width:300, renderCell: (params) =>
    //   {
    //     console.log(params?.row?.orgtype);

    //     return params?.row?.orgtype?.orgtypeName},
    //   } ,

    // { field: "parent", headerName: 'Parent Division', width:150,
    //   renderCell: (params) => params?.row?.parent?.orguname ||'No Parent',
    //  },
    {
      field: "Action",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => (
        <Switch
          checked={row.active}
          onChange={(e) => handleChangeStatus(row.id, e.target.checked)}
        />
      ),
    },

    {
      field: "Action1",
      headerName: "Edit",
      description: "This column has a value getter and is not sortable.",
      IconButton: <EditIcon />,
      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      ),
    },

    {
      field: "Action2",
      headerName: "Delete",
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleDialogOpen("delete", row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  const chartData = orgus?.map((item) => ({
    id: item.id,
    value: item.active,
    label: item.orguname,
  }));
  const data1 = [
    { id: 0, value: 10, label: "series A" },
    { id: 1, value: 15, label: "series B" },
    { id: 2, value: 20, label: "series C" },
  ];

  return (
    <div>
      <Container>
        <PieChart
          width={600}
          height={300}
          series={[
            {
              data: chartData,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            },
          ]}
        />
      </Container>
      <div>
        <Button variant="contained" onClick={handleAdd}>
          {" "}
          Add Organization Unit <AddIcon />
          <hr />
        </Button>

        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={orgus}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 50, 100]}
            slots={{ toolbar: GridToolbar }}
          />
        </div>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Confirm Action</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to {dialogType} this product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmAction} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <OrguModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          currentOrgu={selectedOrgu}
        />
      </div>
    </div>
  );
};

export default OrgunitList;
