import { Grid, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
<>
    <Grid>
            <Grid item xs={12} pb={2}>
              <Typography align='center'
                variant="h5"
                p={1}
                color="#ffffff"
                sx={{ backgroundColor: "rgb(55,10,.84)" }}
              >
               © 2024 - All rights are reserved.
             {/* <Row><Col><Col></Col></Col><Col></Col><Col></Col><Col><Typography variant="body2"  color="#ffffff"> <NavLink target='_blank' tag={ReactLink} to="https://www.facebook.com/DCGurugramOfficial"> <FacebookIcon/></NavLink></Typography></Col><Col> <Typography variant="body2"  color="#ffffff"><NavLink  target='_blank' tag={ReactLink} to="https://twitter.com/DC_Gurugram"> <TwitterIcon /> </NavLink></Typography></Col></Row>  */}
               </Typography>
             
            
            </Grid>
          </Grid>
  </>
  )
}

export default Footer