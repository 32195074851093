import React, { useState } from 'react';

import Navbar from '../../Pages/components/Navbar';
import { Box, Typography } from '@mui/material';
import CvisitForm from './CvisitForm';
import CvisitTable from './CvisitTable';
import Sidenav from './Sidenav';

const Cvisit = () => {

  const [cvisit, setCvisit] = useState(null);
  return (
    <>
    <Navbar/> <Box height={70}/>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant='h3' align='center'  > CE Sites Visites...</Typography><hr/>
        <Box height={15}/>
         <div className="App">
         <CvisitForm cvisit={cvisit} setCvisit={setCvisit}/>
         <CvisitTable onEdit={setCvisit} />
         </div>
          
        </Box>
      </Box>
   </>
  );
}

export default Cvisit;
