import React from 'react';
import DesignationList from './DesignationList';
import Navbar from '../../Pages/components/Navbar';
import Sidenav from './Sidenav';
import { Box, Typography } from '@mui/material';

const Designation = () => {
  return (
    <div>

    <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant='h5' color={'darkblue'} align='center'>Designation Management</Typography>
            <hr/><Box height={10}/>
              <DesignationList />
            </Box>
          </Box>
        </div>
  );
}

export default Designation;
