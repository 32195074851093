import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Box, TextField, Switch, FormControlLabel, Typography } from '@mui/material';
import { addDesig, updateDesig } from '../../Redux/desigReducer';
import { toast } from 'react-toastify';
const DesigModal = ({ open, onClose, currentDesig }) => {
    const [designame, setDesigname] = useState('');
    const [description, setDescription] = useState('');
   
    const [active, setActive] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentDesig) {
            setDesigname(currentDesig.designame);
            setDescription(currentDesig.description);    
            setActive(currentDesig.active);
        }
    }, [currentDesig]);

    const handleSave = () => {
        const desig = { designame, description, active };
        if (currentDesig) {
            dispatch(updateDesig({ id: currentDesig.id, desig })).then(() => {
                toast.success('Orgnization  type updated successfully!');   
            }).catch((error) => {
                toast.error('Failed to add Orgnization type!');
            });
          
        } else {
            dispatch(addDesig(desig)).then(() => {
            toast.success('Designation addded successfully!');   
        }).catch((error) => {
            toast.error('Failed to add Designation !!!');
        });
            
        }
        onClose();
    };

    

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 500 }}>
                <Typography variant='h6' color={'darkblue'}>Manage Employee Designation</Typography><hr/>
                <TextField
                    fullWidth
                    label="Designation Name"
                    value={designame}
                    onChange={(e) => setDesigname(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                />
                {/* <TextField
                type='date'
                    fullWidth
                   // label="Date of Birth"
                   placeholder='Date of Birth'
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    margin="normal"
                /> */}
                <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                />
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </Box>
        </Modal>
    );
};

export default DesigModal;
