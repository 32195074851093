import React from 'react'
import WorkList from './WorkList'
import Navbar from '../../Pages/components/Navbar'
import { Box, Typography } from '@mui/material'
import Sidenav from './Sidenav'

const Work = () => {
  return (
    <div>

    <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant='h3' align='center'  > Projects/Works List</Typography><hr/>
              <WorkList />
            </Box>
          </Box>
        </div>
  )
}

export default Work