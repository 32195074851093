import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import { axios, BASE_URL } from './apiConfig';


export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await axios.get(`${BASE_URL}/api/users/`);
  return response.data;
});
export const fetchUsersDetailsById = createAsyncThunk('users/fetchUsersDetailsById', async () => {
  const response = await axios.get(`${BASE_URL}/api/users/userdetails`);
  return response.data;
});

export const addUser = createAsyncThunk('users/addUser', async (user) => {
  const response = await axios.post(`${BASE_URL}/api/users`, user);
  return response.data;
});

export const updateUser = createAsyncThunk('users/updateUser', async ({ id, user }) => {
  const response = await axios.put(`${BASE_URL}/api/users/${id}`, user);
  return response.data;
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (id) => {
  await axios.delete(`${BASE_URL}/api/users/${id}`);
  return id;
}); 

export const changeUserStatus = createAsyncThunk('users/changeUserStatus', async ({ id, active }) => {
  const response = await axios.patch(`${BASE_URL}/api/users/${id}/status?active=${active}`);
  return response.data;
});

export const fetchActiveUsers = createAsyncThunk('users/fetchActiveUsers', async () => {
  const response = await axios.get(`${BASE_URL}/api/users/users-activecounts`); 
  return response.data;
});
export const fetchInActiveUsers = createAsyncThunk('users/fetchInActiveUsers', async () => {
  const response = await axios.get(`${BASE_URL}/api/users/users-inactivecounts`);
  return response.data;
});
const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    status: 'idle',
    error: null,
    users1: [],
    users2: [],
    usersById:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder


    .addCase(fetchActiveUsers.fulfilled, (state, action) => { 
      state.status = 'succeeded';
      state.users1 = action.payload;
    })
    .addCase(fetchInActiveUsers.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.users2 = action.payload;
    })
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersDetailsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.usersById = action.payload;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.users.findIndex(user => user.id === action.payload.id);
        state.users[index] = action.payload;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter(user => user.id !== action.payload);
      })

      .addCase(changeUserStatus.fulfilled, (state, action) => {
        const index = state.users.findIndex((user) => user.id === action.payload.id);
        state.users[index] = action.payload;
    })
  },
});

export default usersSlice.reducer;