import React from 'react'
import CeDashbaord from './CeDashboard'


const Cexecutive = () => {
  return (
    <div><CeDashbaord/></div>
  )
}

export default Cexecutive