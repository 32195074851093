import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changeDivisionStatus, deleteDivision, fetchDivisions } from '../../Redux/divisionReducer';
import { toast } from 'react-toastify';
import {
    Button,
    List,
    ListDesigText,
    IconButton,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    ListItem,
    ListItemText,
  } from "@mui/material";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DivisionModal from './DivisionModal';
const DivisionList = () => {
    const dispatch = useDispatch();
  const divisions = useSelector((state) => state.divisions.divisions);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentDivisionId, setCurrentDivisionId] = useState(null);

  useEffect(() => {
    console.log(divisions);

    dispatch(fetchDivisions());
  }, [dispatch]);

  const handleEdit = (division) => {
    setSelectedDivision(division);
    setModalOpen(true);
  };

  const handleDeleteDivision = (divisionId) => {
    dispatch(deleteDivision(divisionId))
      .then(() => {
        toast.success("Divisionnation deleted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to add desig!");
      });
  };

  const handleAdd = () => {
    setModalOpen(true);
    setSelectedDivision(null);
  };
  const handleChangeStatus = (divisionId, active) => {
    dispatch(changeDivisionStatus({ divisionId, active }))
      .then(() => {
        toast.success("Division status updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update desig status!");
      });
  };

  const handleDialogOpen = (type, divisionId) => {
    setDialogType(type);
    setCurrentDivisionId(divisionId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentDivisionId(null);
    setDialogType("");
  };

  const handleConfirmAction = () => {
    if (dialogType === "delete") {
      handleDeleteDivision(currentDivisionId);
    }
    handleDialogClose();
  };

  const columns = [
    { field: "divisionId", headerName: "ID", width: 70 },
    { field: "divisionName", headerName: "Division", width: 290 },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "Action1",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.divisionId, e.target.checked)}/>,
       
    },

    {
      field: "Action",
      headerName: "Edit",
      description: "This column has a value getter and is not sortable.",
      IconButton: <EditIcon />,
      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      ),
    },

    {
        field: 'Action2',
     //  headerName: 'Action',
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleDialogOpen("delete", row.divisionId)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
<div>
      <Button variant="contained" onClick={handleAdd}>
        Add Division <Add/>
      </Button>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={divisions}
          columns={columns}
          getRowId={(row) => row.divisionId}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
   
        />
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogType} this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <DivisionModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        currentDivision={selectedDivision}
      />
    </div>
  )
}

export default DivisionList