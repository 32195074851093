import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEmployees, deleteEmployee } from '../../Redux/employeeSlice';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmployeeForm from './EmployeeForm';
import { Add } from '@mui/icons-material';

const EmployeeTable = ({ designations, posts, employees }) => {
  const dispatch = useDispatch();
  const employeeList = useSelector((state) => state.employees.employees);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    console.log(employeeList);
    
    dispatch(fetchEmployees());
  },[dispatch]);

  const handleEdit = (employee) => {
    setSelectedEmployee(employee);
    setOpenFormDialog(true);
  };

  const handleDelete = (employee) => {
    setSelectedEmployee(employee);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = () => {
    dispatch(deleteEmployee(selectedEmployee.id));
    setOpenDeleteDialog(false);
  };

  const columns = [
    { field: 'id', headerName: 'Sr. No.', width: 150 },
    { field: 'employeeCode', headerName: 'Employee Code', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 100 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'designame', headerName: 'Designation', width: 200 },
    { field: 'orguname', headerName: 'Division', width: 200 },
    { field: 'postname', headerName: 'Post Name', width: 200 },

    // { field: 'designame', headerName: 'Designation', width: 200, renderCell: (params) => params?.row?.designation?.designame},
    // { field: 'postname', headerName: 'Post Name', width: 200 },
    // { field: 'orguname', headerName: 'Division Name', width: 200 },


    // { field: 'designation', headerName: 'Designation', width: 150, renderCell: (params) => params?.row?.designation?.designame },
    // { field: 'post', headerName: 'Post', width: 150, renderCell: (params) => params?.row?.post?.postname },
    // { field: 'orgu', headerName: 'Division', width: 150, renderCell: (params) => params?.row?.orgu?.orguname },
    //  { field: 'reportingTo', headerName: 'Reporting Officer', width: 200,
    //    renderCell: (params) => {              
    //     return params?.row?.reportingTo?.map(r=>r.name).join(', ') },
    //    },
      //   {s
      //   console.log(params.row);        
      //   return params?.row?.reportingTo?.map(r=>r.name).join(', ')      
      //  }
     // },
     
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  const handleAdd = () => {
    setOpenFormDialog(true);
   
  };
  return (
    <>

<Button variant="contained" onClick={handleAdd}>
        Add Employee <Add/>
      </Button>
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid rows={employeeList} columns={columns} pageSize={5}
         slots={{ toolbar: GridToolbar }}
        />
      </div>

      {/* Employee Form Dialog */}
      <EmployeeForm
        open={openFormDialog}
        handleClose={() => setOpenFormDialog(false)}
        employee={selectedEmployee}
        designations={designations}
        posts={posts}
        employees={employees}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>Are you sure you want to delete {selectedEmployee?.name}?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmployeeTable;