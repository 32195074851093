import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axios, BASE_URL } from './apiConfig';


export const fetchWorksUnderProgress = createAsyncThunk('works/fetchWorksUnderProgress', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksUnderProgress`);
    const data = response.data; 
     return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksDelay = createAsyncThunk('works/fetchWorksDelay', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksDelay`);
    const data = response.data;
   console.log(data);
 return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksCompleted = createAsyncThunk('works/fetchWorksCompleted', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksCompleted`);
    const data = response.data;
 return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksOnhold = createAsyncThunk('works/fetchWorksOnhold', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksOnhold`);
    const data = response.data;
return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksPending = createAsyncThunk('works/fetchWorksPending', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksPending`);
    const data = response.data;
  return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksUnderProgressInfra1 = createAsyncThunk('works/fetchWorksUnderProgressInfra1', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksUnderProgressInfra1`);
    const data = response.data; 
     return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksDelayInfra1 = createAsyncThunk('works/fetchWorksDelayInfra1', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksDelayInfra1`);
    const data = response.data;
   console.log(data);
 return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksCompletedInfra1 = createAsyncThunk('works/fetchWorksCompletedInfra1', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksCompletedInfra1`);
    const data = response.data;
 return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksOnholdInfra1 = createAsyncThunk('works/fetchWorksOnholdInfra1', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksOnholdInfra1`);
    const data = response.data;
return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksPendingInfra1 = createAsyncThunk('works/fetchWorksPendingInfra1', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksPendingInfra1`);
    const data = response.data;
  return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const fetchWorksUnderProgressInfra2 = createAsyncThunk('works/fetchWorksUnderProgressInfra2', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksUnderProgressInfra2`);
    const data = response.data; 
     return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksDelayInfra2 = createAsyncThunk('works/fetchWorksDelayInfra2', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksDelayInfra2`);
    const data = response.data;
   console.log(data);
 return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksCompletedInfra2 = createAsyncThunk('works/fetchWorksCompletedInfra2', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksCompletedInfra2`);
    const data = response.data;
 return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksOnholdInfra2 = createAsyncThunk('works/fetchWorksOnholdInfra2', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksOnholdInfra2`);
    const data = response.data;
return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksPendingInfra2 = createAsyncThunk('works/fetchWorksPendingInfra2', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksPendingInfra2`);
    const data = response.data;
  return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const fetchWorks = createAsyncThunk('works/fetchWorks', async (thunkAPI) => {
  try {   
    // const response = await axios.get(`/api/works`);
    const response = await axios.get(`/api/works`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const fetchWorksCreatedBy = createAsyncThunk('works/fetchWorksCreatedBy', async (thunkAPI) => {
  try {   
    // const response = await axios.get(`/api/works`);
    const response = await axios.get(`/api/works/listWorkByCreatedBy`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const fetchWorksByXen = createAsyncThunk('works/fetchWorksXen', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listProjectByXen`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const fetchWorksAllCountWithDivision = createAsyncThunk('works/fetchWorksAllCountWithDivision', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksAllDivisionWithNameCounts`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const fetchWorksInfra1 = createAsyncThunk('works/fetchWorksInfra1', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksInfra1`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchWorksInfra2 = createAsyncThunk('works/fetchWorksInfra2', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listWorksInfra2`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const fetchWorksByExen = createAsyncThunk('works/fetchWorks', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listProjectByXen`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const fetchWorksByIdName = createAsyncThunk('works/fetchWorksByIdName', async (thunkAPI) => {
  try {   
    const response = await axios.get(`/api/works/listProjectByIdName`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const addWork = createAsyncThunk('works/addWork', async (work,thunkAPI) => {
  try {
    const response = await axios.post(`/api/works`,work);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const updateWork = createAsyncThunk('works/updateWork', async ({ id, work,thunkAPI }) => {
   try {
    const response = await axios.put(`/api/works/${id}`,work);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});


export const updateWorkIdName = createAsyncThunk('works/updateWorkIdName', async ({workId,employeeIds,thunkAPI }) => {
  try {
   const response = await axios.patch(`/api/works/work/${workId}/assign-employees`, employeeIds);
   const data = response.data;
   return data;
} catch (error) {
   return thunkAPI.rejectWithValue(error.message);
}
});



export const deleteWork = createAsyncThunk('works/deleteWork', async (id,thunkAPI) => {
  // await axios.delete(`${BASE_URL}/api/works/${id}`);
  // return id;
  try {
     await axios.delete(`/api/works/${id}`);
   
    return id;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
}); 

export const changeWorkStatus = createAsyncThunk('works/changeWorkStatus', async ({ id, active,thunkAPI }) => {
   try {
    const response = await axios.patch(`/api/works/${id}/status?active=${active}`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const updateWorkStatus = createAsyncThunk('works/updateWorkStatus', async ({ id, wstatus,thunkAPI }) => {
   try {
    const response = await axios.patch(`/api/works/${id}/wstatus?wstatus=${wstatus}`);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
const worksSlice = createSlice({
  name: 'works',
  initialState: {
    works: [],
    status: 'idle',
    wstatus: 'idle',
    error: null,
    worksIdName: [],
    worksUnderProgress:[],
    worksDelay:[],
    worksCompleted:[],
    worksOnhold:[],
    worksPending:[],
    worksUnderProgressInfra1:[],
    worksDelayInfra1:[],
    worksCompletedInfra1:[],
    worksOnholdInfra1:[],
    worksPendingInfra1:[],
    worksUnderProgressInfra2:[],
    worksDelayInfra2:[],
    worksCompletedInfra2:[],
    worksOnholdInfra2:[],
    worksPendingInfra2:[],
    worksInfra1:[],
    worksInfra2:[],
    worksCountsWithDivsions:[],
    worksByXen:[],
    worksCreatedBy:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.works = action.payload;
      })
      .addCase(fetchWorksCreatedBy.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksCreatedBy = action.payload;
      })
      .addCase(fetchWorksByXen.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksByXen = action.payload;
      })
      .addCase(fetchWorksAllCountWithDivision.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksCountsWithDivsions = action.payload;
      })
      .addCase(fetchWorksInfra1.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorksInfra1.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksInfra1 = action.payload;
      })
      .addCase(fetchWorksInfra2.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksInfra2= action.payload;
      })
      .addCase(fetchWorksByIdName.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksIdName = action.payload;
      })


      .addCase(fetchWorksUnderProgress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksUnderProgress = action.payload;
      })
      .addCase(fetchWorksDelay.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksDelay = action.payload;
      })
      .addCase(fetchWorksCompleted.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksCompleted = action.payload;
      })
      .addCase(fetchWorksOnhold.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksOnhold = action.payload;
      })
      .addCase(fetchWorksPending.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksPending = action.payload;
      })

      .addCase(fetchWorksUnderProgressInfra1.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksUnderProgressInfra1 = action.payload;
      })
      .addCase(fetchWorksDelayInfra1.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksDelayInfra1 = action.payload;
      })
      .addCase(fetchWorksCompletedInfra1.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksCompletedInfra1 = action.payload;
      })
      .addCase(fetchWorksOnholdInfra1.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksOnholdInfra1 = action.payload;
      })
      .addCase(fetchWorksPendingInfra1.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksPendingInfra1 = action.payload;
      })


      .addCase(fetchWorksUnderProgressInfra2.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksUnderProgressInfra2 = action.payload;
      })
      .addCase(fetchWorksDelayInfra2.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksDelayInfra2 = action.payload;
      })
      .addCase(fetchWorksCompletedInfra2.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksCompletedInfra2 = action.payload;
      })
      .addCase(fetchWorksOnholdInfra2.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksOnholdInfra2 = action.payload;
      })
      .addCase(fetchWorksPendingInfra2.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.worksPendingInfra2 = action.payload;
      })



      .addCase(fetchWorks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addWork.fulfilled, (state, action) => {
        state.works.push(action.payload);
      })
      .addCase(updateWork.fulfilled, (state, action) => {
        const index = state.works.findIndex(work => work.id === action.payload.id);
        state.works[index] = action.payload;
      })
      .addCase(updateWorkIdName.fulfilled, (state, action) => {
        const index = state.works.findIndex(work => work.id === action.payload.id);
        state.works[index] = action.payload;
      })
      .addCase(deleteWork.fulfilled, (state, action) => {
        state.works = state.works.filter(work => work.id !== action.payload);
      })

      .addCase(changeWorkStatus.fulfilled, (state, action) => {
        const index = state.works.findIndex((work) => work.id === action.payload.id);
        state.works[index] = action.payload;
    })
      .addCase(updateWorkStatus.fulfilled, (state, action) => {
        const index = state.works.findIndex((work) => work.id === action.payload.id);
        state.works[index] = action.payload;
    })
  },
});

export default worksSlice.reducer;