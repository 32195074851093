import React from 'react'
import Navbar from '../../Pages/components/Navbar'
import Sidenav from './Sidenav'
import { Box, Typography } from '@mui/material'
import ProjectsList from './ProjectsList'
import WorkList from './WorkList'

const JeProjects = () => {
  return (
    <div>
    <Navbar/> <Box height={70}/>
              <Box sx={{ display: "flex" }}>
                <Sidenav />
        
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant='h3' align='center'  > JE Projects List</Typography><hr/>
                <Box height={10}/>
                
                  {/* <ProjectsList /> */}

                  <WorkList/>
                </Box>
              </Box>
    
        </div>
  )
}

export default JeProjects