import React from 'react'
import UserList from './UserList'
import Navbar from '../../Pages/components/Navbar';
import Sidenav from './Sidenav';
import { Box, Typography } from '@mui/material';
const User = () => {
  return (
    <div>

    <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Typography variant='h5' color={'darkblue'} align='center'>User Management</Typography>
              <hr/><Box height={10}/>
              <UserList />
            </Box>
          </Box>
        </div>
  )
}

export default User