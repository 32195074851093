import React from 'react'
import Sidenav from './Sidenav';
import Navbar from '../../Pages/components/Navbar';
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import CountUp from "react-countup";

const ContractorDashboard = () => {
    // const dispatch = useDispatch();
    // const orgus = useSelector((state) => state.orgus.orgus);
    // const activeCount = useSelector((state) => Array.isArray(state.orgus.orgus1)&&state.orgus.orgus1.length&&state.orgus.orgus1[0].tcount);
    // const inactiveCount = useSelector((state) => Array.isArray(state.orgus.orgus2)&&state.orgus.orgus2.length&&state.orgus.orgus2[0].tcount);
   
    // useEffect(() => {
      //console.log(orgus);
//       dispatch(fetchOrgus());
//       dispatch(fetchActiveOrgus());      
//       dispatch(fetchInActiveOrgus()); 
//   }, []);
  

 
  return (
    <div>
    <Navbar /> <Box height={70} />
    <Box sx={{ display: "flex" }}>
      <Sidenav />
 
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant='h4' align='center' py={2}>Contractor Dashbaord</Typography><hr/>
      <Box height={20}/>
        <Grid container spacing={2}>
          <Grid item xs={8}>
              <Stack spacing={2} direction={"row"}>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white"}} >
            {/* sx={{ maxWidth: 345, height:300 }} */}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" color={'lightblue'}>
                 Contractor Assigned Projects: <CountUp delay={0.3} end= "50" duration={2.9} />
                  {/* <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> */}
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'lightgreen'}>
                  Active: <CountUp delay={0.3} end= "45" duration={2.9} />
                   {/* <CountUp delay={0.3} end= {activeCount} duration={2.9} /> */}
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'#fe5644'}>
                  On Hold: <CountUp delay={0.3} end= "5" duration={2.9} />
                  {/* <CountUp delay={0.3} end= {inactiveCount} duration={2.9} /> */}
                </Typography>
               
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Pending Projects...:<CountUp delay={0.3} end= "42" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {/* projects <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> */}
                  {/* projects <CountUp delay={0.3} end= "8" duration={2.9} /> */}
                </Typography>
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Under Progress Projects:<CountUp delay={0.3} end= "6" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {/* projects <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> */}
                  {/* projects <CountUp delay={0.3} end= "8" duration={2.9} /> */}
                </Typography>
              </CardContent>
            
            </Card>
            </Stack>
          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                Completed Projects: 
               </Typography>
               <Typography variant="body2" color="#ffffff">
                <CountUp delay={0.3} end= "6" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
            Deley Projects:
               </Typography>
               <Typography variant="body2" color="#ffffff" >
               <CountUp delay={0.3} end= "44" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
           </Stack>
          </Grid>
          <Box height={20} />
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item xs={8}>

              <Stack spacing={2} direction='row'>
          <Card sx={{ maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>             
           </Card>
          <Card sx={{ maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>             
           </Card>
           </Stack>

          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ maxWidth: 345 }}>
             
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>
           
           </Card>
          <Card sx={{ maxWidth: 345 }}>
             
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>
           
           </Card>
           </Stack>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  </div>
  );
}

export default ContractorDashboard