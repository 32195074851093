import React, { useEffect } from "react";
import Navbar from "../../Pages/components/Navbar";
import Sidenav from "./Sidenav";
import {
  fetchWorks,
  fetchWorksAllCountWithDivision,
} from "../../Redux/workReducer";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";
import CountUp from "react-countup";
import ProjectListByDivision from "./ProjectListByDivision";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch();
  const works = useSelector((state) => state.works.works);
  const worksCountWithDivisions = useSelector(
    (state) => state.works.fetchWorksAllCountWithDivision
  );
  useEffect(() => {
    console.log(works);

    dispatch(fetchWorksAllCountWithDivision());

    dispatch(fetchWorks());
  }, [dispatch]);
  return (
    <div>
      <Navbar /> <Box height={70} />
      <Box>
        {/* <Sidenav /> */}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h4" align="center" py={2} color="#002984">
            Chief Executive Officer
          </Typography>
          <hr />
          <Box height={20} />

          <Grid container spacing={10}>
            <Grid size={6}>
              <Card>
                {" "}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    All Projects:
                    <CountUp delay={0.3} end={works.length} duration={2.9} />
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <PieChart />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={6}>
              <Card>
                {" "}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    All Projects:
                    <CountUp delay={0.3} end={works.length} duration={2.9} />
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <BarChart />
                  </Typography>
                </CardContent>{" "}
              </Card>
            </Grid>
          </Grid>

          <Box height={10} />
          <Box height={10} />
          <ProjectListByDivision />
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
