import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Ceo/components/Dashboard';
import AdminApp from '../Ceo/components/AdminApp';
import Ceo from '../Ceo/components/Ceo';
import File from '../Ceo/components/File';
import ProjectList from '../Ceo/components/ProjectList';
import CeoProjects from '../Ceo/components/CeoProjects';
import FileUpload from '../Ceo/components/Fileupload';
import Video from '../Ceo/components/Video';


const CeoRouter = () => {
  return (
    <div>
      <Routes>
            <Route path='/*' element={<Ceo />}/>
            <Route path='/dashboard' element={<Dashboard />}/>
           
            <Route path='/fileup' element={<File />}/>
            
            <Route path='/projects' element={<CeoProjects />}/>
            <Route path='/fileup/:workId' element={<FileUpload />}/>

        </Routes>
    </div>
  );
}

export default CeoRouter;
