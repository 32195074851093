import React, { uheEffect, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPosts,
  deletePost,
  changePostStatus,
} from "../../Redux/postReducer";
import {
  Button,
  List,
  ListPostText,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PostModal from "./PostModal";
import { toast } from "react-toastify";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";

const PostList = () => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.posts);
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentPostId, setCurrentPostId] = useState(null);

  useEffect(() => {
    console.log(posts);

    dispatch(fetchPosts());
  }, [dispatch]);

  const handleEdit = (post) => {
    setSelectedPost(post);
    setModalOpen(true);
  };

  const handleDeletePost = (id) => {
    dispatch(deletePost(id))
      .then(() => {
        toast.success("Iten deleted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to add post!");
      });
  };

  const handleAdd = () => {
    setModalOpen(true);
    setSelectedPost(null);
  };
  const handleChangeStatus = (id, active) => {
    dispatch(changePostStatus({ id, active }))
      .then(() => {
        toast.success("Post status updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update post status!");
      });
  };

  const handleDialogOpen = (type, postId) => {
    setDialogType(type);
    setCurrentPostId(postId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentPostId(null);
    setDialogType("");
  };

  const handleConfirmAction = () => {
    if (dialogType === "delete") {
      handleDeletePost(currentPostId);
    }
    handleDialogClose();
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "postname", headerName: "Organization  Type", width: 290 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "fullName", headerName: "Created By", width: 200 },
    {
      field: "Action1",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.id, e.target.checked)}/>,
 
    },

    {
      field: "Action",
      headerName: "Edit",
      description: "This column has a value getter and is not sortable.",
      IconButton: <EditIcon />,
      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      ),
    },

    {
         field: 'Action2',
     headerName: 'Delete',
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleDialogOpen("delete", row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <Button variant="contained" onClick={handleAdd}>
        Add Post <Add/>
      </Button>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={posts}
          columns={columns}
         // getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
        />
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogType} this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PostModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        currentPost={selectedPost}
      />
    </div>
  );
};

export default PostList;
