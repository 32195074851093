import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axios, BASE_URL } from './apiConfig';

export const fetchEmployees = createAsyncThunk('employees/fetchEmployees', async (thunkAPI) => {
try { 
    const response = await axios.get(`${BASE_URL}/api/employees/getempcustomlist`);
    const data = response.data;
    //console.log(data);
    
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});
export const fetchEmployeesByIdName = createAsyncThunk('employees/fetchEmployeesByIdName', async (thunkAPI) => {
try { 
    const response = await axios.get(`${BASE_URL}/api/employees/getempidname`);
    const data = response.data;
    console.log(data);
    
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}
});

export const createEmployee = createAsyncThunk('employees/createEmployee', async (employee,thunkAPI) => {
//   const response = await axios.post(`${BASE_URL}/api/employees`, employee);
//   return response.data;

  try {
    const response = await axios.post(`${BASE_URL}/api/employees`,employee);
    const data = response.data;
    return data;
} catch (error) {
    return thunkAPI.rejectWithValue(error.message);
}


});

export const updateEmployee = createAsyncThunk('employees/updateEmployee', async ({ id, employee }) => {
  const response = await axios.put(`${BASE_URL}/api/employees/${id}`, employee);
  return response.data;
});

export const deleteEmployee = createAsyncThunk('employees/deleteEmployee', async (id) => {
  await axios.delete(`${BASE_URL}/api/employees/${id}`);
  return id;
});


export const fetchActiveEmployees = createAsyncThunk('employees/fetchActiveEmployees', async () => {
  const response = await axios.get(`${BASE_URL}/api/employees/emps-activecounts`); 
  return response.data;
});
export const fetchInActiveEmployees = createAsyncThunk('employees/fetchInActiveEmployees', async () => {
  const response = await axios.get(`${BASE_URL}/api/employees/emps-inactivecounts`);
  return response.data;
});

const employeeSlice = createSlice({
  name: 'employees',
  initialState: {
    employees: [],
    status: 'idle',
    error: null,
    employeesByIdName:[],

    employees1: [],
    employees2: [],
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchActiveEmployees.fulfilled, (state, action) => { 
      state.status = 'succeeded';
      state.employees1 = action.payload;
    })
    .addCase(fetchInActiveEmployees.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.employees2 = action.payload;
    })

      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.employees = action.payload;
      })
      .addCase(fetchEmployeesByIdName.fulfilled, (state, action) => {
        state.employeesByIdName = action.payload;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.employees.push(action.payload);
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        const index = state.employees.findIndex((emp) => emp.id === action.payload.id);
        state.employees[index] = action.payload;
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.employees = state.employees.filter((emp) => emp.id !== action.payload);
      });
  },
});

export default employeeSlice.reducer;