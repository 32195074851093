import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadFile, uploadMultipleFiles } from '../../Redux/fileSlice';
import { Box, Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const FileUpload = () => {
    const {workId}=useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleMultipleFilesChange = (e) => {
        setSelectedFiles(e.target.files);
    };

    const handleSingleFileUpload = () => {
        if (selectedFile) {
            dispatch(uploadFile(selectedFile))
                .then(() => toast.success('File uploaded successfully'))
                .catch((error) => toast.error('File upload failed'));
        }
    };

    const handleMultipleFilesUpload = () => {
        if (selectedFiles.length > 0) {
            dispatch(uploadMultipleFiles(Array.from(selectedFiles)))
                .then(() => toast.success('Files uploaded successfully'))
                .catch((error) => toast.error('File upload failed'));
        }
    };

    return (
        <div>
     
            <TextField type="file" onChange={handleFileChange} />
            <Button variant="contained" color="primary" onClick={handleSingleFileUpload}>
                Upload File
            </Button><br/>
            <Box height={20}/>
            <TextField type="file" inputProps={{ multiple: true }} onChange={handleMultipleFilesChange} />
            <Button variant="contained" color="secondary" onClick={handleMultipleFilesUpload}>
                Upload Multiple Files
            </Button>
        </div>
    );
};

export default FileUpload;