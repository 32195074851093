import React from 'react'

import Highcharts, { chart, color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3D from "highcharts/highcharts-3d";
if (typeof Highcharts === "object") {
  Highcharts3D(Highcharts);
}

const BarChart = () => {
  const options = {
    chart: {
      type: "column",
      options3d: {
        enabled: true,
        alpha: 10,
        beta: -45,
        depth:0,
        viewDistance:25
      },
    },
    plotOptions: {
      column: { depth: 65,shadow:true, size:'90%',
        dataLables:{
            enabled:false
        }
      },
    },
    title:{
text:'Divisions Projects ',
align:'center',
    },
    xAxis:{
categories:['Infra-I','Infra-II','Mobility','Electrical','Urban Planning','Urban Environment']
    },
    yAxis:{
title:{
    enabled:true
}
    },
    series: [
      {
        type: "column",
        data: [
          { name: "Infra-I", y: 55,color:'#aaaaaa' },
          { name: "Infra-II", y: 45,color:'#fd1214' },
          { name: "Mobility", y: 15 ,color:'#fd12b4'},
          { name: "Electrical", y: 62 ,color:'#fdff14'},
          { name: "Urban Planning", y: 55 ,color:'#fd9614'},
          { name: "Urban Environment", y: 55 ,color:'#fa1554'},
        ],
      },
    ],
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};




export default BarChart