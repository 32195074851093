import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSvisit, updateSvisit } from "../../Redux/svisitSlice";
import { TextField, Button, Box, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

const SvisitForm = ({ svisit, isEdit }) => {
  const {workId}=useParams();
  // const [latitude,setLatitude]=useState('')
  // const [longitude,setLongitude]=useState('');
  // useEffect(()=>{
  //     navigator.geolocation.getCurrentPosition((position)=>{
  //     setLatitude(position.coords.latitude);
  //     setLongitude(position.coords.longitude);
  //     })
  //     })
  const [review, setReview] = useState(svisit ? svisit.review : "");
  const [remark, setRemark] = useState(svisit ? svisit.remark : "");
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setImages(e.target.files);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    // formData.append('name', name);
    // formData.append('age', age);
    formData.append(
      "svisit",
      new Blob([JSON.stringify({ review, remark,workId:workId })], {
        type: "application/json",
      })
    );
    Array.from(images).forEach((image) => {
      formData.append("images", image);
    });

    if (isEdit) {
      dispatch(updateSvisit({ id: svisit.id, svisitData: formData }));
    } else {
      dispatch(createSvisit(formData));
    }
  };

  return (
    <>
      <Box
        sx={{ p: 3, bgcolor: "background.paper", margin: "auto", width: 1024 }}
      >
        <Typography variant="h6">
          SE Visiting Info... <hr />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <form>
              <TextField
                label="Review"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                fullWidth
              /><Box height={5}/>
              <TextField
                label="Remarks"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                fullWidth
              /><Box height={5}/>
              <input type="file" multiple onChange={handleFileChange} />
              <Button onClick={handleSubmit}>
                {isEdit ? "Update" : "Create"}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SvisitForm;
