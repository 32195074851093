import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Box,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { addOrgu, updateOrgu } from "../../Redux/ouReducer";

import { toast } from "react-toastify";
import { fetchOrgtypeIdName } from "../../Redux/otReducer";

import { fetchOrgus } from "../../Redux/ouReducer";

const OrguModal = ({ open, onClose, currentOrgu }) => {
  const [orguname, setOrguname] = useState("");
  const [description, setDescription] = useState("");
  const [orgtypeId, setOrgtypeId] = useState("");
  const [parentId, setParentId] = useState("");
  const orgtypes = useSelector((state) => state.orgtypes.orgtypes);
  const orgus = useSelector((state) => state.orgus.orgus);
  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log(orgutps);
    dispatch(fetchOrgus());
    dispatch(fetchOrgtypeIdName());
  }, [dispatch]);

  useEffect(() => {
    console.log(currentOrgu);

    if (currentOrgu) {
      setOrguname(currentOrgu.orguname);
      setDescription(currentOrgu.description);
      setOrgtypeId(currentOrgu.orgtypeId);
      setParentId(currentOrgu.parentId);
      setActive(currentOrgu.active);
    }
  }, [currentOrgu]);

  const handleSave = () => {
    const orgu = { orguname, description, orgtypeId, parentId,active };
    if (currentOrgu) {
      dispatch(updateOrgu({ id: currentOrgu.id, orgu }))
        .then(() => {
          toast.success("Orgnization  Unit updated successfully!");
        })
        .catch((error) => {
          toast.error("Failed to add Orgnization unit!");
        });
    } else {
      dispatch(addOrgu(orgu))
        .then(() => {
          console.log(orgu);

          toast.success("Orgnization Unit addded successfully!");
        })
        .catch((error) => {
          toast.error("Failed to add Orgnization unit!");
        });
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{ p: 3, bgcolor: "background.paper", margin: "auto", width: 500 }}
      >
        <Typography variant="h6" color={"darkblue"}>
          Manage Organization Unit
        </Typography>
        <hr />
        <TextField
          autoComplete="off"
          fullWidth
          label="Organization Type Name"
          value={orguname}
          onChange={(e) => setOrguname(e.target.value)}
          margin="normal"
        />
        <FormControl fullWidth>
          <InputLabel id="category-select-label">Parent Division</InputLabel>
          <Select
            autoComplete="off"
            labelId="category-select-label"
            id="category-select"
            label="Organization Type"
            value={parentId}
            onChange={(e) => setParentId(e.target.value)}
          >
            {orgus.map((orgu1) => (
              <MenuItem key={orgu1.id} value={orgu1.id}>
                {orgu1.orguname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="category-select-label">Organization Type</InputLabel>
          <Select
            autoComplete="off"
            labelId="category-select-label"
            id="category-select"
            label="Organization Type"
            value={orgtypeId}
            onChange={(e) => setOrgtypeId(e.target.value)}
          >
            {orgtypes.map((orgutp1) => (
              <MenuItem key={orgutp1.orgtypeId} value={orgutp1.orgtypeId}>
                {orgutp1.orgtypeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          autoComplete="off"
          fullWidth
          multiline
          rows={2}
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
        />
        {/* <TextField
                type='date'
                    fullWidth
                   // label="Date of Birth"
                   placeholder='Date of Birth'
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    margin="normal"
                /> */}
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          }
          label="Active"
        />
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default OrguModal;
