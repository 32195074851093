import React from 'react'
import ProjectList from './ProjectList'
import Navbar from '../../Pages/components/Navbar'
import { Box, Typography } from '@mui/material'
import Sidenav from './Sidenav'
import WorkList from './WorkList'

const SeProjects = () => {
  return (
    <div>
<Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant='h3' align='center'  > CE Projects List</Typography><hr/>
            <Box height={10}/>
            
              {/* <ProjectList /> */}

              <WorkList/>
            </Box>
          </Box>

    </div>
  )
}

export default SeProjects