import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Cexecutive from '../Ce/components/Cexecutive';
import CeDashboard from '../Ce/components/CeDashboard';
import CeProjects from '../Ce/components/CeProjects';

import File from '../Ce/components/File'
import Cvisit from '../Ce/components/Cvisit';
import AdminApp from '../Ce/components/AdminApp';


const CeRouter = () => {
  return (
    <div>
      <Routes>
      <Route path='/*' element={<Cexecutive />}/>
            <Route path='/cedashboard' element={<CeDashboard />}/>
            <Route path='/adminapp' element={<AdminApp />}/>
            <Route path='/projects' element={<CeProjects />}/>
            <Route path='/cvisit/:workId' element={<Cvisit />}/>
            <Route path='/fileup' element={<File />}/>

        </Routes>
    </div>
  );
}

export default CeRouter;
