import React from 'react'
import { useLocation } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import { Box, Modal } from '@mui/material';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    outline: "none",
    boxShadow: 24,
    p: 4,
  };
  const AuthModal = ({handleClose,open}) => {
    const location =useLocation();
    return (
      <div>
     
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {location.pathname==='/login'?<RegisterForm onClose={handleClose}/>:<LoginForm onClose={handleClose}/>}
       
          </Box>
        </Modal>
      </div>
    );
  };
  
  export default AuthModal;