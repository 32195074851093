import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCvisit, updateCvisit } from '../../Redux/cvisitSlice'
import { TextField, Button, Box, Typography, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

const CvisitForm = ({ cvisit, isEdit, setCvisit }) => {

    const {workId}=useParams();
    // const [latitude,setLatitude]=useState('')
    // const [longitude,setLongitude]=useState('');
    // useEffect(()=>{
    //     navigator.geolocation.getCurrentPosition((position)=>{
    //     setLatitude(position.coords.latitude);
    //     setLongitude(position.coords.longitude);
    //     })
    //     })
    const [review, setReview] = useState(cvisit ? cvisit.review : '');
    const [remark, setRemark] = useState(cvisit ? cvisit.remark : '');
    const [images, setImages] = useState([]);

    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        setImages(e.target.files);
    };


    useEffect(() => {
        if (cvisit) {
          setReview(cvisit.review);
          setRemark(cvisit.remark);
        } else {
          setReview('');
          setRemark('');
        }
      }, [cvisit]);

    const handleSubmit = () => {
        const formData = new FormData();
        // formData.append('name', name);
        // formData.append('age', age);
        formData.append('cvisit', new Blob([JSON.stringify({ review, remark,workId:workId })], { type: 'application/json' }));
        Array.from(images).forEach(image => {
            formData.append('images', image);
        });

        if (cvisit) {
            dispatch(updateCvisit({ id: cvisit.id, cvisitData: formData }));
        } else {
            dispatch(createCvisit(formData));
        }
    };



    return (
        <>
        <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 1024 }}>
          
          <Typography variant='h6'>CE Visiting Info... <hr/></Typography>
          <Grid container spacing={2}>
          <Grid item xs={12}>

        <form>
            <TextField label="Review" value={review} onChange={(e) => setReview(e.target.value)} fullWidth /><Box height={5}/>
            <TextField label="Remarks" value={remark} onChange={(e) => setRemark(e.target.value)} fullWidth /><Box height={5}/>
            <input type="file" multiple onChange={handleFileChange} />
            <Button  variant='contained' onClick={handleSubmit}>{cvisit ? 'Update' : 'Create'}</Button>
        </form>
        </Grid>
        </Grid>
        </Box>
        </>
    );
};


export default CvisitForm;
