import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  fetchCvisits, deleteCvisit } from "../../Redux/cvisitSlice";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { BASE_URL } from "../../Redux/apiConfig";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditNotifications } from "@mui/icons-material";

const CvisitTable = ({ onEdit }) => {
  const dispatch = useDispatch();
  const { cvisits } = useSelector((state) => state.cvisits);
  const { cimages } = useSelector((state) => state.cvisits.cimages);

  useEffect(() => {
    dispatch(fetchCvisits());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this cvisit?")) {
      dispatch(deleteCvisit(id));
    }
  };


  
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "review", headerName: "Review", width: 200 },
    { field: "remark", headerName: "Remarks", width: 100 },
    { field: "created", headerName: "Created Date", width: 100 },
    { field: "createdBy", headerName: "Created By", width: 100 },
    {
      field: "cimages",
      headerName: "Images",
      width: 500,
      height:300,
      
      renderCell: (params) => (
        <div style={{display:'flex'}}>
          {params.row.cimages?.map((image) => (
            <Stack spacing={2} direction={'row'}>
            <>
                <a
            color="green"
                target="_blank"
                href={`${BASE_URL}/api/cvisits/image/${image.cimageName}`}
              >
                View {image.id}
              </a>
              <img
                src={`${BASE_URL}/api/cvisits/image/${image.cimageName}`}
               
                alt="Avatar" style={{ width: '50px', height: '50px', }}
                key={image.id}
                
              />
              
            </>
            </Stack>
          ))}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
        field: "Action",
        headerName: "Action",
        description: "This column has a value getter and is not sortable.",

        width: 60,
        renderCell: ({ row }) => (
          <IconButton onClick={() => onEdit(row)}>
            <EditNotifications />
          </IconButton>
        ),
      },
  ];

  return (
    <>
      {/* <Grid2 container spacing={3}>
        <Grid2 item xs={6}>123s</Grid2>
        <Grid2 item xs={6}>
        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: 56, justifyContent: 'center', position:"relative" }}>
        <Avatar alt="Svisit" src={rose} sx={{
          width: 80,
          height: 80,
          position: "absolute",
          left: -40,  // 50% overlap outside the card
          top: -5,
          zIndex:20
        }}/>
      </Box>
      <CardContent>
        <Typography variant="h5" component="div">
          Title
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Some description goes here.
        </Typography>
      </CardContent>
    </Card>
        </Grid2>
      </Grid2> */}

      <div>
        <DataGrid
          rows={cvisits}
          columns={columns}
          getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          // slots={{ toolbar: GridToolbar }}
   
        />
      </div>
    </>
  );
};

export default CvisitTable;
