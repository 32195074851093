import React, { useEffect } from "react";
import Sidenav from "./Sidenav";
import Navbar from "../../Pages/components/Navbar";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveOrgus,
  fetchInActiveOrgus,
  fetchOrgus,
} from "../../Redux/ouReducer";
import CountUp from "react-countup";
import img1 from "../../img/rose.png";
import img2 from "../../img/gmdaL.png";
import "./Mystype.css";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { fetchActiveOrgtypes, fetchInActiveOrgtypes, fetchOrgtypes } from "../../Redux/otReducer";
import { fetchActivePosts, fetchInActivePosts, fetchPosts } from "../../Redux/postReducer";
import { fetchActiveDesigs, fetchDesigs, fetchInActiveDesigs } from "../../Redux/desigReducer";
import { fetchActiveEmployees, fetchEmployees, fetchInActiveEmployees } from "../../Redux/employeeSlice";
import { fetchActiveUsers, fetchInActiveUsers, fetchUsers } from "../../Redux/userReducer";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orgus = useSelector((state) => state.orgus.orgus);
  const orgtypes = useSelector((state) => state.orgtypes.orgtypes);
  const posts = useSelector((state) => state.posts.posts);
  const desigs = useSelector((state) => state.desigs.desigs);
  const employees = useSelector((state) => state.employees.employees);
  const users = useSelector((state) => state.users.users);
  const activeCount = useSelector(
    (state) =>
      Array.isArray(state.orgus.orgus1) &&
      state.orgus.orgus1.length &&
      state.orgus.orgus1[0].tcount
  );
  const inactiveCount = useSelector(
    (state) =>
      Array.isArray(state.orgus.orgus2) &&
      state.orgus.orgus2.length &&
      state.orgus.orgus2[0].tcount
  );
  const activeCountOT = useSelector(
    (state) =>
      Array.isArray(state.orgtypes.orgtypes1) &&
      state.orgtypes.orgtypes1.length &&
      state.orgtypes.orgtypes1[0].tcount
  );
  const inactiveCountOT = useSelector(
    (state) =>
      Array.isArray(state.orgtypes.orgtypes2) &&
      state.orgtypes.orgtypes2.length &&
      state.orgtypes.orgtypes2[0].tcount
  );
  const activeCountPO = useSelector(
    (state) =>
      Array.isArray(state.posts.posts1) &&
      state.posts.posts1.length &&
      state.posts.posts1[0].tcount
  );
  const inactiveCountPO = useSelector(
    (state) =>
      Array.isArray(state.posts.posts2) &&
      state.posts.posts2.length &&
      state.posts.posts2[0].tcount
  );
  const activeCountDes = useSelector(
    (state) =>
      Array.isArray(state.desigs.desigs1) &&
      state.desigs.desigs1.length &&
      state.desigs.desigs1[0].tcount
  );
  const inactiveCountDes = useSelector(
    (state) =>
      Array.isArray(state.desigs.desigs2) &&
      state.desigs.desigs2.length &&
      state.desigs.desigs2[0].tcount
  );
  const activeCountEmp = useSelector(
    (state) =>
      Array.isArray(state.employees.employees1) &&
      state.employees.employees1.length &&
      state.employees.employees1[0].tcount
  );
  const inactiveCountEmp = useSelector(
    (state) =>
      Array.isArray(state.employees.employees2) &&
      state.employees.employees2.length &&
      state.employees.employees2[0].tcount
  );
  const activeCountU = useSelector(
    (state) =>
      Array.isArray(state.users.users1) &&
      state.users.users1.length &&
      state.users.users1[0].tcount
  );
  const inactiveCountU = useSelector(
    (state) =>
      Array.isArray(state.users.users2) &&
      state.users.users2.length &&
      state.users.users2[0].tcount
  );

  useEffect(() => {
    //console.log(orgus);
    dispatch(fetchOrgus());
    dispatch(fetchActiveOrgus());
    dispatch(fetchInActiveOrgus());
    dispatch(fetchOrgtypes());
    dispatch(fetchActiveOrgtypes());
    dispatch(fetchInActiveOrgtypes());
    dispatch(fetchPosts());
    dispatch(fetchActivePosts());
    dispatch(fetchInActivePosts());
    dispatch(fetchDesigs());
    dispatch(fetchActiveDesigs());
    dispatch(fetchInActiveDesigs());
    dispatch(fetchEmployees());
    dispatch(fetchActiveEmployees());
    dispatch(fetchInActiveEmployees());
    dispatch(fetchUsers());
    dispatch(fetchActiveUsers());
    dispatch(fetchInActiveUsers());
  }, []);

  return (
    <div>
      <Navbar /> <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h4" align="center" py={2} color={"darkblue"}>
            Administrator Dashbaord
          </Typography>
          <hr style={{ height: 2, color: "gray" }} />
          <Box height={20} />
          <Grid spacing={2} container>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Division
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    ALL Division:{" "}
                    <CountUp delay={0.3} end={orgus.length} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    Active Division:{" "}
                    <CountUp delay={0.3} end={activeCount} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "red",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    InActive Division:{" "}
                    <CountUp delay={0.3} end={inactiveCount} duration={2.9} />
                  </Typography>

                  <figure>
                    <Avatar
                      onClick={() => navigate("/admin/orgunit")}
                      // src={img2}
                      sx={{
                        // bgcolor:"white",
                        m: 0.5,
                        width: 80,
                        height: 75,
                        leftboxShadow: 30,
                        color: "green",
                        verticalAlign: "middle",
                      }}
                    >
                      Division
                    </Avatar>
                  </figure>
                </div>
              </Card>
            </Grid>
            <Grid item size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    color="white"
                    bgcolor={"#40e0d0"}
                  >
                    Employee
                  </Typography>


                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    ALL Employee:{" "}
                    <CountUp delay={0.3} end={employees.length} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    Active Employee:{" "}
                    <CountUp delay={0.3} end={activeCountEmp} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "red",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    InActive Employee:{" "}
                    <CountUp delay={0.3} end={inactiveCountEmp} duration={2.9} />
                  </Typography>
                  <figure>
                    <Avatar
                      onClick={() => navigate("/admin/emp")}
                      // src={img2}
                      sx={{
                        m: 0.5,
                        width: 90,
                        height: 75,
                        leftboxShadow: 30,
                        color: "darkgreen",
                        verticalAlign: "middle",
                      }}
                    >
                      Employee
                    </Avatar>
                  </figure>
                </div>
              </Card>
            </Grid>
            <Grid item size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    color="white"
                    bgcolor={"#40e0d0"}
                  >
                    Designation
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    ALL Designation:{" "}
                    <CountUp delay={0.3} end={desigs.length} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    Active Designation:{" "}
                    <CountUp delay={0.3} end={activeCountDes} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "red",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    InActive Designation:{" "}
                    <CountUp delay={0.3} end={inactiveCountDes} duration={2.9} />
                  </Typography>

                  <figure>
                    <Avatar
                      onClick={() => navigate("/admin/desig")}
                      // src={img2}
                      sx={{
                        m: 0.5,
                        width: 120,
                        height: 75,
                        leftboxShadow: 0,
                        color: "darkgreen",
                        verticalAlign: "middle",
                      }}
                    >
                      Designation
                    </Avatar>
                  </figure>
                </div>
              </Card>
            </Grid>
            {/* <Grid item size={4}>
            <Card style={{opacity:'60'}}> 
      <CardContent>
      <Typography sx={{ "&:hover": { color: "green",backgroundColor:"yellow", } }} gutterBottom variant="h4" style={{borderRadius:'10'}} component="div" align="center" color="white" bgcolor={'#40e0d0'}>
          Division
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        <Button size="small">Share</Button>
        </Typography>
      </CardContent>
      <CardActions>
        
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

            
            </Grid> */}
          </Grid>
          <Grid spacing={2} container>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    color="white"
                    bgcolor={"#40e0d0"}
                  >
                    Organization Type
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    ALL Org.:{" "}
                    <CountUp delay={0.3} end={orgtypes.length} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    Active Org.:{" "}
                    <CountUp delay={0.3} end={activeCountOT} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "red",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    InActive Org.:{" "}
                    <CountUp delay={0.3} end={inactiveCountOT} duration={2.9} />
                  </Typography>

                  <figure>
                    <Avatar
                      onClick={() => navigate("/admin/orgtype")}
                      // src={img2}
                      sx={{
                        // bgcolor:"white",
                        m: 0.5,
                        width: 80,
                        height: 75,
                        leftboxShadow: 30,
                        color: "darkgreen",
                        verticalAlign: "middle",
                      }}
                    >
                      Org. Type
                    </Avatar>
                  </figure>
                </div>
              </Card>
            </Grid>
            <Grid item size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    User
                  </Typography>

                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    ALL User:{" "}
                    <CountUp delay={0.3} end={users.length} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    Active User:{" "}
                    <CountUp delay={0.3} end={activeCountU} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "red",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    InActive User:{" "}
                    <CountUp delay={0.3} end={inactiveCountU} duration={2.9} />
                  </Typography>
                  <figure>
                    <Avatar
                      onClick={() => navigate("/admin/user")}
                      // src={img2}
                      sx={{
                        m: 0.5,
                        width: 80,
                        height: 75,
                        leftboxShadow: 30,
                        color: "darkgreen",
                        verticalAlign: "middle",
                      }}
                    >
                      User
                    </Avatar>
                  </figure>
                </div>
              </Card>
            </Grid>
            <Grid item size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Post
                  </Typography>


                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    ALL Post:{" "}
                    <CountUp delay={0.3} end={posts.length} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    Active Post:{" "}
                    <CountUp delay={0.3} end={activeCountPO} duration={2.9} />
                  </Typography>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "red",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="right"
                  >
                    InActive Post:{" "}
                    <CountUp delay={0.3} end={inactiveCountPO} duration={2.9} />
                  </Typography>

                  <figure>
                    <Avatar
                      onClick={() => navigate("/admin/post")}
                      // src={img2}
                      sx={{
                        m: 0.5,
                        width: 90,
                        height: 75,
                        leftboxShadow: 30,
                        color: "darkgreen",
                        verticalAlign: "middle",
                        bgcolor:"#e3effd"
                      }}
                    >
                      Post
                    </Avatar>
                  </figure>
                </div>
              </Card>
            </Grid>
            {/* <Grid item size={4}>
            <Card style={{opacity:'60'}}> 
      <CardContent>
      <Typography sx={{ "&:hover": { color: "green",backgroundColor:"yellow", } }} gutterBottom variant="h4" style={{borderRadius:'10'}} component="div" align="center" color="white" bgcolor={'#40e0d0'}>
          Division
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        <Button size="small">Share</Button>
        </Typography>
      </CardContent>
      <CardActions>
        
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

            
            </Grid> */}
          </Grid>

          {/* <Grid container spacing={2}  >  */}
          {/* <Grid item sx={6}>
         
        </Grid> */}
          {/* <Grid item sx={4}>
        <Card>
            <CardContent>
              <div className="cand-box">
                <figure>
                  <Avatar  src={img2} sx={{ width: 100,py:2, height: 80, leftboxShadow:30,color:'green', verticalAlign:'middle'}}>
                   
                  </Avatar>
                </figure>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sx={4}>
        <Card>
            <CardContent>
              <div className="cand-box">
                <figure>
                  <Avatar  src={img1} sx={{ width: 100, height: 150, leftboxShadow:30,color:'green', verticalAlign:'middle'}}>
                   
                  </Avatar>
                </figure>
              </div>
            </CardContent>
          </Card>
        </Grid> */}
          {/* </Grid> */}
          {/* <Grid container spacing={2}>
          <Grid item xs={8}>
              <Stack spacing={2} direction={"row"}>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }} >           
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" color={'lightblue'}>
                  ALL Organization: <CountUp delay={0.3} end= {orgus.length} duration={2.9} />
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'lightgreen'}>
                  Active Organization: <CountUp delay={0.3} end= {activeCount} duration={2.9} />
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'#fe5644'}>
                  InActive Organization: <CountUp delay={0.3} end= {inactiveCount} duration={2.9} />
                </Typography>              
              </CardContent>
            
            </Card>
            <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
             
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Employee List
                </Typography>
                <Typography variant="body2" color="#ff">
                <CountUp delay={0.3} end= "53" duration={2.9} />
                </Typography>
              </CardContent>
            
            </Card>
             <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                 Inactive Employee List
                </Typography>
                <Typography variant="body2" color="#ff">
                <CountUp delay={0.3} end= "5" duration={2.9} />
                </Typography>
              </CardContent>
            
            </Card>
            </Stack>
          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Users:
               </Typography>
               <Typography variant="body2" color="#ffff">
               <CountUp delay={0.3} end= "153" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Active Users:
               </Typography>
               <Typography variant="body2" color="#fffff">
               <CountUp delay={0.3} end= "42" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
           </Stack>
          </Grid>
          <Box height={20} />
        </Grid> */}
        </Box>
      </Box>
    </div>
  );
};

export default AdminDashboard;
