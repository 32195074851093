import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Box, TextField, Switch, FormControlLabel, Typography, MenuItem } from '@mui/material';
import { addDivision, updateDivision } from '../../Redux/divisionReducer';
import { toast } from 'react-toastify';
import { fetchOrgtypes } from '../../Redux/otReducer';
import { fetchDivisions } from '../../Redux/divisionReducer';
import { fetchVenders } from '../../Redux/venderReducer';
const DivisionModal = ({ open, onClose, currentDivision, currentDivision1, setCurrentDivision1  }) => {
    const [divisionName, setDivisionName] = useState('');
    const[orgtypeId,setOrgtypeId]=useState('');
    const[divisiondId,setDivisiondId]=useState('');
    const [description, setDescription] = useState('');
   
    const [active, setActive] = useState(true);
    const dispatch = useDispatch();
    const orgtypes=useSelector((state)=>state.orgtypes.orgtypes);
    const divisions=useSelector((state)=>state.divisions.divisions);
    useEffect(()=>{
        console.log(orgtypes);
        dispatch(fetchOrgtypes());
        dispatch(fetchDivisions());
        
    },[dispatch])

    useEffect(() => {
        if (currentDivision) {
            setDivisionName(currentDivision.divisionName);
            setDivisiondId(currentDivision.parentd?currentDivision.parentd.divisiondId:'')
          
            setOrgtypeId(currentDivision.orgrtypeId)
            setDescription(currentDivision.description);    
            setActive(currentDivision.active);
        }
    }, [currentDivision]);

    const handleSave = () => {
        const division = { divisionName,divisiondId:divisiondId? { divisionId: divisiondId } : null ,orgtypeId, description, active };
        if (currentDivision) {
            dispatch(updateDivision({ divisionId: currentDivision.divisionId, division:{divisionName,divisiondId:divisiondId,orgtypeId:orgtypeId, description, active} })).then(() => {
                toast.success('Division  type updated successfully!');   
                console.log(currentDivision);
                
            }).catch((error) => {
                toast.error('Failed to add Division!');
            });
          
        } else {
            dispatch(addDivision(division)).then(() => {
            toast.success('Division addded successfully!');   
        }).catch((error) => {
            toast.error('Failed to add Divisionnation !!!');
        });
            
        }
        onClose();
    };

  return (
    <Modal open={open} onClose={onClose}>
            <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 500 }}>
                <Typography variant='h6' color={'darkblue'}>Manage Employee Division</Typography><hr/>
                <TextField
                    fullWidth
                    label="Division Name"
                    value={divisionName}
                    onChange={(e) => setDivisionName(e.target.value)}
                    margin="normal"
                />
 <TextField
                select
                label="Parent"
                value={divisiondId}
                onChange={(e) => setDivisiondId(e.target.value)}
                fullWidth
            >
                <MenuItem value="">None</MenuItem>
                {divisions.map((dd) => (
                    <MenuItem key={dd.divisionId} value={dd.divisionId}>
                        {dd.divisionName}
                    </MenuItem>
                ))}
            </TextField>

 <TextField
                select
                label="Organization Type"
                value={orgtypeId}
                onChange={(e) => setOrgtypeId(e.target.value)}
                fullWidth
            >
                <MenuItem value="">None</MenuItem>
                {orgtypes.map((ot) => (
                    <MenuItem key={ot.orgrtypeId} value={ot.orgtypeId}>
                        {ot.orgtypeName}
                    </MenuItem>
                ))}
            </TextField>

                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                />
                {/* <TextField
                type='date'
                    fullWidth
                   // label="Date of Birth"
                   placeholder='Date of Birth'
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    margin="normal"
                /> */}
                <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                />
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </Box>
        </Modal>
  )
}

export default DivisionModal