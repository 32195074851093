import React from 'react'
import Navbar from '../../Pages/components/Navbar'
import { Box, Typography } from '@mui/material'
import Sidenav from './Sidenav'
import VideoForm from './VideoForm'
import VideoList from './VideoList'

const Video = () => {
  return (
    <div>

<Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant='h3' align='center'  > Video Uploading</Typography><hr/>
            <Box height={10}/>
            
              <VideoForm />
              <VideoList/>
            </Box>
          </Box>

    </div>
  )
}

export default Video