import React, { uheEffect, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrgtypes, deleteOrgtype, changeOrgtypeStatus } from '../../Redux/otReducer';
import { Button, List,  ListOrgtypeText, IconButton, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, ListItem, ListItemText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OrgutpModal from './OrgutpModal';
import { toast } from 'react-toastify';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { AddCircleRounded } from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { render } from '@testing-library/react';
const OrgtypeList = () => {
    const dispatch = useDispatch();
    const orgtypes = useSelector((state) => state.orgtypes.orgtypes);
    // const owner = useSelector((state) => state.orgtypes.orgtypes.owner);
  //  console.log(owner);
    
    const [selectedOrgutp, setSelectedOrgutp] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [active, setActive] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState('');
    const [currentOrgtypeId, setCurrentOrgtypeId] = useState(null);
   
    useEffect(() => {
        //console.log(orgtypes);
        
        dispatch(fetchOrgtypes());
    }, [dispatch]);

    const handleEdit = (orgtype) => {
        setSelectedOrgutp(orgtype);
        setModalOpen(true);
    };

    const handleDeleteOrgtype = (orgtypeId) => {
        dispatch(deleteOrgtype(orgtypeId)).then(() => {
            toast.success('Orgtype deleted successfully!');   
        }).catch((error) => {
            toast.error('Failed to add orgtype!');
        });
    };


    const handleAdd = () => {
        
        setModalOpen(true);
        setSelectedOrgutp(null);
    };
    const handleChangeStatus = (orgtypeId, active) => {
        dispatch(changeOrgtypeStatus({ orgtypeId, active })).then(() => {
            toast.success('Orgtype status updated successfully!');
        }).catch((error) => {
            toast.error('Failed to update orgtype status!');
        });
    };

    const handleDialogOpen = (type, orgtypeId) => {
        setDialogType(type);
        setCurrentOrgtypeId(orgtypeId);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentOrgtypeId(null);
        setDialogType('');
    };

    const handleConfirmAction = () => {
        if (dialogType === 'delete') {
            handleDeleteOrgtype(currentOrgtypeId);
        }
        handleDialogClose();
    };


    const columns = [
        

        { field: 'orgtypeId', headerName: 'ID', worgtypeIdth: 70 },
        { field: 'orgtypeName', headerName: 'Division  Type', worgtypeIdth:290 },
        { field: 'description', headerName: 'Description', worgtypeIdth:600 },
        { field: 'owner', headerName: 'Owner', worgtypeIdth:600,
            renderCell: (params) => params?.row?.owner?.fullName,
         },
        {
            field: 'Action',
             headerName: 'Status',
            description: 'This column has a value getter and is not sortable.',
            IconButton:<DeleteIcon/>,   
          
            worgtypeIdth: 60,
            renderCell: ({ row }) =>  <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.orgtypeId, e.target.checked)}/>,
          
       
         
          },
              
        {
          field: 'Action1',
          headerName: 'Edit',
          description: 'This column has a value getter and is not sortable.',
         IconButton:<EditIcon/>,          
          worgtypeIdth: 10,
          renderCell: ({ row }) => <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
      </IconButton>,
        
        },

        
        {
      field: 'Action2',
         headerName: 'Delete',
          description: 'This column has a value getter and is not sortable.',
          IconButton:<DeleteIcon/>,   
        
          worgtypeIdth: 10,
          renderCell: ({ row }) =>  <IconButton onClick={() => handleDialogOpen('delete', row.orgtypeId)}>
          <DeleteIcon />
      </IconButton>
        
        },

        


        
      ];


    return (
        <div>
            <Button variant="contained" onClick={handleAdd}>Add Organization Type <AddCircleRounded /><hr/></Button>
            {/* <List>
                {orgtypes.map((orgtype) => (
                    <ListItem key={orgtype.orgtypeId}>
                        <ListItemText primary={orgtype.orgtypename} secondary={orgtype.description}   />
                        {/* <ListItemText  primary={orgtype.dob.split('-').reverse().join('-')}  /> */}
                        {/* <Switch checked={orgtype.active} disabled /> */}
                        {/* <Switch checked={orgtype.active} onChange={(e) => handleChangeStatus(orgtype.orgtypeId, e.target.checked)} />
                        <IconButton onClick={() => handleEdit(orgtype)}>
                            <EditIcon />
                        </IconButton> */}
                        {/* <IconButton onClick={() => handleDelete(orgtype.orgtypeId)}>
                            <DeleteIcon />
                        </IconButton> */}

                        {/* <IconButton edge="end" onClick={() => handleDialogOpen('delete', orgtype.orgtypeId)}>
                                <DeleteIcon />
                            </IconButton>
                    </ListItem> */}
                {/* ))} */}
            {/* </List> */} 

            <div style={{ height: 400, worgtypeIdth: '100%' }}>
      <DataGrid
        rows={orgtypes}
         columns={columns}
         getRowId={(row) => row.orgtypeId}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10,50,100]}
        slots={{ toolbar: GridToolbar }}
      />
    </div>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {dialogType} this product?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmAction} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <OrgutpModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                currentOrgtype={selectedOrgutp}
            />
        </div>
    );
};


export default OrgtypeList;
