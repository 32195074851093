import React from 'react'
import VenderList from './VenderList'
import Navbar from '../../Pages/components/Navbar'
import Sidenav from './Sidenav'
import { Box, Typography } from '@mui/material'

const Vender = () => {
  return (
    <div>

    <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
             <Typography variant='h3' align='center'  > Venders List</Typography><hr/>
             <Box height={10}/>
              <VenderList />
            </Box>
          </Box>
        </div>
  )
}

export default Vender