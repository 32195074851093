import React from 'react'
import SeDashbaord from './SeDashboard'


const Sexecutive = () => {
  return (
    <div><SeDashbaord/></div>
  )
}

export default Sexecutive