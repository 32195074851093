import React from 'react';
import AssignProTeamList from './AssignProTeamList';
import AssingProTeam from './AssingProTeam';
import Navbar from '../../Pages/components/Navbar';
import { Box, Typography } from '@mui/material';
import Sidenav from './Sidenav';

const Assignproject = () => {
  return (
    <div>

    <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
             <Typography variant='h3' align='center'  >Assign Projects/Works to Team </Typography><hr/>
             <Box height={10}/>
             <AssingProTeam/>
              <AssignProTeamList />
            </Box>
          </Box>
        </div>
  );
}

export default Assignproject;
