import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos } from '../../Redux/videoSlice';
import { BASE_URL } from '../../Redux/apiConfig';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const VideoList = () => {
    const dispatch = useDispatch();
    const videos = useSelector((state) => state.videos.videos);
   
    const [images, setImages] = useState('');
   

    useEffect(() => {
        dispatch(fetchVideos());
    //    fetchFilesName();
    }, []);
    const columns = [
      { field: "videoId", headerName: "ID",  },
      { field: "title", headerName: "Video Title",
         renderCell: (params) =>  <Link to={`${BASE_URL}/api/v1/videos/stream/${params.videoId}`} >View {params.videoId} {params.value}</Link>
       // <Link  target="_blank" to={`${BASE_URL}/api/v1/videos/stream/${video1.videoId}`} >View {video1.videoId}</Link>,
      },
     
       { field: "description", headerName: "Description" },   
    
    ];

  return (
    <div>
      <Box height={70}/>
    <Box>
    {/* <div style={{ height: 400, width: '100%'  }}> */}
       {/* <DataGrid
         rows={videos}
         columns={columns}
         getRowId={(row) => row.videoId}
         initialState={{
           pagination: {
             paginationModel: { page: 0, pageSize: 5 },
           },
         }}
         pageSizeOptions={[5, 10, 50, 100]}
         slots={{ toolbar: GridToolbar }}
        
       />
     </div> */}
         </Box>

 <List>
            {videos.map((video1) => (
                <ListItem key={video1.videoId}>
                    <ListItemText primary={video1.title} secondary={`${video1.description}`} />
           <Link  target="_blank" to={`${BASE_URL}/api/v1/videos/stream/${video1.videoId}`}  >View {video1.videoId}</Link>
           {/* <img key={video1.id}  src={`${BASE_URL}/api/v1/${video1.filePath}`}  style={{ width: '200px', height: 'auto', margin: '10px' }}/> */}
               
               <video width={400} height={300} src={`${BASE_URL}/api/v1/videos/stream/${video1.videoId}`} controls></video> </ListItem>


            ))}
        </List>

    </div>
  )
}

export default VideoList