import React, { useEffect, useState } from "react";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3D from "highcharts/highcharts-3d";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorksAllCountWithDivision } from "../../Redux/workReducer";
if (typeof Highcharts === "object") {
  Highcharts3D(Highcharts);
}

const PieChart = () => {

  const worksListCountWithDiv=useSelector((state) => state.works.worksListCountWithDiv);

  const dispatch=useDispatch();

  useEffect(()=>{
dispatch(fetchWorksAllCountWithDivision());
  },[dispatch])


  const chartData = worksListCountWithDiv?.map((item) => ({
    id: item.id,
    value: item.orguname,
    label: item.pendingCount,
  }));
  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 55,
        beta: 1,
      },
    },
    plotOptions: {
      pie: { depth: 65,shadow:true, size:'90%',
        dataLables:{
            enabled:false
        }
      },
    },
    title:{
text:'Divisions Projects ',
align:'center',
    },
    series: [
      {
        type: "pie",
    

        data:
        // worksListCountWithDiv?.map((item) => ({
        //   id: item.id,
        //   value: item.orguname,
        //   label: item.pendingCount,
        // }))
         [
          { name: "Infra-I", y: 2,color:'#aaaaaa' },
          { name: "Infra-II", y: 22 },
          { name: "Mobility", y: 55 },
          { name: "Electrical", y: 62 },
          { name: "Urban Planning", y: 55 },
          { name: "Urban Environment", y: 55 },
        ],
      },
    ],
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default PieChart;
