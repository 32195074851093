import React from 'react'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css';



import img1 from './imgs/banner-7.jpg'
import img2 from './imgs/banner-6.jpg'
import img3 from './imgs/banner-5.jfif'
import img4 from './imgs/banner-4.jpg'
import img5 from './imgs/banner-3.jpg'
import img6 from './imgs/banner-2.jpg'
import img7 from './imgs/banner-1.jpg'

// import img1 from './imgs/KHELO_GURUGRAM__XGREEN_GURUGRAM.jpg'
// import img2 from './imgs/KHELO_GURUGRAM.jpg'
// import img3 from './imgs/KHELO_X_GREEN_GGM.jpg'
// import img4 from './imgs/PLANTAION_BANNER.jpg'
// import img5 from './imgs/SPORTS_BANNER.jpg'

const homeCarosuselData=[
  {
    "image": img1,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    "image": img2,
    altText: 'Slide 2',
    caption: 'Slide 2'
  },
  {
    "image": img3,
    altText: 'Slide 3',
    caption: 'Slide 3'
  },
  {
    "image": img4,
    altText: 'Slide 4',
    caption: 'Slide 4'
  },
  {
    "image": img5,
    altText: 'Slide 5',
    caption: 'Slide 5'
  },
  
  {
    "image": img6,
    altText: 'Slide 6',
    caption: 'Slide 6'
 
  },
  {
    "image": img7,
    altText: 'Slide 7',
    caption: 'Slide 7'
  }

]





const HomeCarousel = () => {
const items=homeCarosuselData.map((item)=><img className='cursor-pointer h-[75vh] w-full' role='presentation' src={item.image} alt=''/>)
    
  return (
    <>
   
  <div  className='h-[50vh] -z-10'>
    
  
     <AliceCarousel        
        items={items}
        disableButtonsControls
        autoPlay
        autoPlayInterval={3000}
        infinite
       
    />
    </div>
    </>
   
  )
}



export default HomeCarousel