import React, { uheEffect, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVenders,
  deleteVender,
  changeVenderStatus,
} from "../../Redux/venderReducer";
import {
  Button,
  List,
  ListVenderText,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VenderModal from "./VenderModal";
import { toast } from "react-toastify";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";

const VenderList = () => {
  const dispatch = useDispatch();
  const venders = useSelector((state) => state.venders.venders);
  const [selectedVender, setSelectedVender] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentVenderId, setCurrentVenderId] = useState(null);

  useEffect(() => {
    console.log(venders);

    dispatch(fetchVenders());
  }, [dispatch]);

  const handleEdit = (post) => {
    setSelectedVender(post);
    setModalOpen(true);
  };

  const handleDeleteVender = (id) => {
    dispatch(deleteVender(id))
      .then(() => {
        toast.success("Iten deleted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to add post!");
      });
  };

  const handleAdd = () => {
    setModalOpen(true);
    setSelectedVender(null);
  };
  const handleChangeStatus = (id, active) => {
    dispatch(changeVenderStatus({ id, active }))
      .then(() => {
        toast.success("Vender status updated successfully!");
      })
      .catch((error) => {
        toast.error("Failed to update post status!");
      });
  };

  const handleDialogOpen = (type, postId) => {
    setDialogType(type);
    setCurrentVenderId(postId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentVenderId(null);
    setDialogType("");
  };

  const handleConfirmAction = () => {
    if (dialogType === "delete") {
      handleDeleteVender(currentVenderId);
    }
    handleDialogClose();
  };

  const columns = [
    { field: "id", headerName: "ID",  },
    { field: "vendername", headerName: "Vender Name",},
    { field: "vaddress", headerName: "Vender Address",  },
    { field: "panno", headerName: "PAN No.",  },
    { field: "accountno", headerName: "Account No.",  },
    { field: "mobileno", headerName: "Mobile No.",  },

    { field: "description", headerName: "Description", },
    {
      field: "Action1",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.id, e.target.checked)}/>,
 
    },

    {
      field: "Action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      IconButton: <EditIcon />,
      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon />
        </IconButton>
      ),
    },

    {
      //   field: 'Action',
      // headerName: 'Action',
      description: "This column has a value getter and is not sortable.",
      IconButton: <DeleteIcon />,

      width: 10,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleDialogOpen("delete", row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <Button variant="contained" onClick={handleAdd}>
        Add Vender <Add/>
      </Button>

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={venders}
          columns={columns}
         // getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
          //checkboxSelection
        />
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogType} this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <VenderModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        currentVender={selectedVender}
      />
    </div>
  );
};




export default VenderList