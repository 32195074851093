import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  MenuItem,
} from "@mui/material";
import { addWork, updateWork } from "../../Redux/workReducer";
import { toast } from "react-toastify";
import { fetchVenders } from "../../Redux/venderReducer";
const WorkModal = ({ open, onClose, currentWork }) => {
  const venders=useSelector((state)=>state.venders.venders);
  const [workname, setWorkname] = useState("");
const[fileno,setFileno]=useState('');
const[wstatus,setWstatus]=useState();
const[aramt,setAramt]=useState('')
//  const[agencyName,setAgencyName]=useState('');
 const[venderId,setVenderId]=useState('');

const[startDate,setSatrtDate]=useState('')
const[timeLimit,setTimeLimit]=useState("");
const[dlp,setDlp]=useState("");
const[sdDateComplition,setSdDateComplition]=useState("");
const[srDateComplition,setSrDateComplition]=useState("");
const[budgetDYear,setBudgetDYear]=useState("");
const[phyStatus,setPhyStatus]=useState("");
const[finStatus,setFinStatus]=useState("");


 
  const [description, setDescription] = useState("");

  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
const[valid,setValid]=useState('');
  const handleValidation=(e)=>{
    const regex = /^[0-9]{1,3}$/;

    const newValue = e.target.value;

    // Check if the new value matches the regex
    if (regex.test(newValue)) {
        setValid(newValue);
    }
    
    //setValid(reg.test(e.target.value));
    //setValue(e.target.value);
    
  }
useEffect(()=>{
  dispatch(fetchVenders());
},[dispatch])
  useEffect(() => {
    if (currentWork) {
      setWorkname(currentWork.workname);
      setFileno(currentWork.fileno);
      setWstatus(currentWork.wstatus);
      setAramt(currentWork.aramt);

      //  setAgencyName(currentWork.agencyName);
      setVenderId(currentWork.venderId);
      setSatrtDate(currentWork.startDate)
      setTimeLimit(currentWork.timeLimit)
      setDlp(currentWork.dlp)
      setSdDateComplition(currentWork.sdDateComplition)
      setSrDateComplition(currentWork.srDateComplition)
      setBudgetDYear(currentWork.budgetDYear)
      setPhyStatus(currentWork.phyStatus)
      setFinStatus(currentWork.finStatus)
     
      setDescription(currentWork.description);
      setActive(currentWork.active);
    }
  }, [currentWork]);

  const handleSave = () => {
    const work = { workname,fileno,wstatus, aramt,startDate,timeLimit,dlp,sdDateComplition,srDateComplition,budgetDYear,phyStatus,finStatus, description, active,venderId };
    if (currentWork) {
      dispatch(updateWork({ id: currentWork.id, work }))
        .then(() => {
          toast.success("Work updated successfully!");
        })
        .catch((error) => {
          toast.error("Failed to add Work!");
        });
    } else {
      dispatch(addWork(work))
        .then(() => {
          toast.success("Work addded successfully!");
        })
        .catch((error) => {
          toast.error("Failed to add Work!");
        });
    }
    onClose();
  };

  const currencies = [
    {
      value: 'Pending',
      label: 'Pending',
    },
    {
      value: 'UnderProgress',
      label: 'Under Progress',
    },
    {
      value: 'Delay',
      label: 'Delay',
    },
    {
      value: 'Completed',
      label: 'Completed',
    },
    {
      value: 'Parked',
      label: 'On hold',
    }
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{ p: 3, bgcolor: "background.paper", margin: "auto", width: 700 }}
      >
        <Typography variant="h6" color={"darkblue"}>
          Manage Work
        </Typography>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {" "}
            <TextField
              fullWidth
              label="File No."
              autoComplete="off"
              value={fileno}
              onChange={(e) => setFileno(e.target.value)}
              margin="normal"
            />
          </Grid>{" "}
          <Grid item xs={4}>
            {" "}
            <TextField
              fullWidth
              label="Alloted/Restracted Amount in Cr."
              autoComplete="off"
              type="number"
              value={aramt}
              onKeyUp={(e)=>handleValidation(e)}
              onChange={(e) => setAramt(e.target.value)}
              margin="normal"
              error={!valid}
            />
          </Grid>
          <Grid item xs={4}>
          <TextField
          fullWidth
          id="wstatus"
          name="wstatus"
          select
          label="Select"
          value={wstatus}
          autoComplete="off"
                onChange={(e) => setWstatus(e.target.value)}
          // helperText="Please select Status of Work"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
          </Grid>
          <Grid item xs={12}>
            {" "}
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Name of Work"
              autoComplete="off"
              value={workname}
              onChange={(e) => setWorkname(e.target.value)}
              margin="normal"
            />
          </Grid>
           <Grid item xs={4}> 
           <TextField
                select
                label=" Select Vender"
                value={venderId}
                onChange={(e) => setVenderId(e.target.value)}
                fullWidth
            >
                <MenuItem value="">None</MenuItem>
                {venders.map((vs) => (
                    <MenuItem key={vs.id} value={vs.id}>
                        {vs.vendername}
                    </MenuItem>
                ))}
            </TextField>

                  {/* <TextField
                fullWidth
                label="Agency Name"
                  autoComplete='off'
                value={agencyName}
                onChange={(e) => setAgencyName(e.target.value)}
                margin="normal"
            /> */}
            
            </Grid> 
            <Grid item xs={4}> <TextField
                    fullWidth
                    label="Date of Start"
                      autoComplete='off'
                      type="date"
                    value={startDate}
                    onChange={(e) => setSatrtDate(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item xs={4}> <TextField
                    fullWidth
                    label="Time Limit"
                      autoComplete='off'
                    value={timeLimit}
                    onChange={(e) => setTimeLimit(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item xs={4}> <TextField
                    fullWidth
                    label="Defect Liabilty Period"
                      autoComplete='off'
                    value={dlp}
                    onChange={(e) => setDlp(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item xs={4}> <TextField
                    fullWidth
                    type="date"
                    label="Date of Complition"
                      autoComplete='off'
                    value={sdDateComplition}
                    onChange={(e) => setSdDateComplition(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item xs={4}> <TextField
                    fullWidth
                    label="Revised Date of Complition"
                      autoComplete='off'
                      type="date"
                    value={srDateComplition}
                    onChange={(e) => setSrDateComplition(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item xs={4}> <TextField
                    fullWidth
                    label="Budget During Year"
                      autoComplete='off'
                    value={budgetDYear}
                    onChange={(e) => setBudgetDYear(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item xs={4}> <TextField
                    fullWidth
                    label="Physical Status of Project/Work"
                      autoComplete='off'
                    value={phyStatus}
                    onChange={(e) => setPhyStatus(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item xs={4}> <TextField
                    fullWidth
                    label="Financial Status of Project/Work"
                      autoComplete='off'
                    value={finStatus}
                    onChange={(e) => setFinStatus(e.target.value)}
                    margin="normal"
                /></Grid>

                {/*<Grid item xs={6}><TextField
                fullWidth
                label="Account No."
                  autoComplete='off'
                value={accountno}
                onChange={(e) => setAccountno(e.target.value)}
                margin="normal"
            /></Grid>
            <Grid item xs={6}> <TextField
                    fullWidth
                      autoComplete='off'
                    label="Mobile No."
                    value={mobileno}
                    onChange={(e) => setMobileno(e.target.value)}
                    margin="normal"
                /></Grid>*/}
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Remarks"
            value={description}
            multiline
            rows={3}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
          />
        </Grid>

        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          }
          label="Active"
        />
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default WorkModal;
