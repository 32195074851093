import React from 'react'
import Navbar from '../../Pages/components/Navbar'
import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import Sidenav from './Sidenav'
import CountUp from "react-countup";

const JeDashboard = () => {
  return (
    <>
     <Navbar /> <Box height={70} />
    <Box sx={{ display: "flex" }}>
      <Sidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant='h4' align='center' py={2}>JE Dashbaord</Typography><hr/>
      <Box height={20}/>
        <Grid container spacing={2}>
          <Grid item xs={8}>
              <Stack spacing={2} direction={"row"}>
            <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
             
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
         JE Assigned Projects :<CountUp delay={0.3} end= "50" duration={2.9} />
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with
                  over 6,000 species, ranging across all continents except
                  Antarctica
                </Typography> */}
              </CardContent>
            
            </Card>
            <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
             
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
            Verified Projects :<CountUp delay={0.3} end= "42" duration={2.9} />
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with
                  over 6,000 species, ranging across all continents except
                  Antarctica
                </Typography> */}
              </CardContent>
            
            </Card>
            <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
             
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                All Projects To be verify :<CountUp delay={0.3} end= "5" duration={2.9} />
                </Typography>
               
              </CardContent>
            
            </Card>
            </Stack>
          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                projects Completed:<CountUp delay={0.3} end= "3" duration={2.9} />
               </Typography>
              
             </CardContent>
           
           </Card>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
              Projects:<CountUp delay={0.3} end= "152" duration={2.9} />
               </Typography>
               {/* <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography> */}
             </CardContent>
           
           </Card>
           </Stack>
          </Grid>
          <Box height={20} />
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item xs={8}>

              <Stack spacing={2} direction='row'>
          <Card sx={{ maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>             
           </Card>
          <Card sx={{ maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>             
           </Card>
           </Stack>

          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ maxWidth: 345 }}>
             
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>
           
           </Card>
          <Card sx={{ maxWidth: 345 }}>
             
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                 Lizard
               </Typography>
               <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography>
             </CardContent>
           
           </Card>
           </Stack>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
    </>
  )
}

export default JeDashboard