import React from 'react';
import Navbar from '../../Pages/components/Navbar';
import Sidenav from './Sidenav';
import { Box, Typography } from '@mui/material';
import AdminaList from './AdminaList';

const AdminApp = () => {
  return (
    <div>

<Navbar/> <Box height={70}/>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant='h3' align='center'  > Administrative Approvals  List</Typography><hr/>
        <Box height={15}/>
          <AdminaList />
        </Box>
      </Box>
    </div>
  );
}

export default AdminApp;
