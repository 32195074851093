import React, { useEffect } from 'react';
import { fetchWorksCreatedBy } from '../../Redux/workReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';

const AssignProTeamList = () => {
  const dispatch=useDispatch();
  const works = useSelector(state => state.works.worksCreatedBy);
  useEffect(() => {
    console.log(works);

    dispatch(fetchWorksCreatedBy());
  }, [dispatch]);

    const columns = [
        { field: 'id', headerName: 'Work Id', width: 150 },
        { field: 'workname', headerName: 'Work Name', width: 150 },
        { field: 'employees', headerName: 'Employees', width: 300, valueGetter: (params) => 
            params?.row?.employees?.map(e => e.name).join(', ') },
    ];
  return (
    <div>
      <h1> Team List</h1>
      <DataGrid rows={works} columns={columns} pageSize={5} getRowId={(row) => row.id}/>
    </div>
  );
}

export default AssignProTeamList;
