import React, { useEffect } from 'react'
import Navbar from './Navbar'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Error = () => {
  const navigate=useNavigate()
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     navigate(-1)
  //   },2000)
  // },[])
  return (
<>

    <Navbar /> <Box height={70} />
    <Box sx={{ display: "flex" }}>
        <div>Contact Administrator !!!!!!!!!!!</div>
        </Box>
        </>
    
  )
}

export default Error